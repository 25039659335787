import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../api';
import './Auth.css';

const UserDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dob, setDob] = useState('');
    const [teamSize, setTeamSize] = useState('');
    const [mainPurpose, setMainPurpose] = useState('');
    const [industry, setIndustry] = useState('');
    const [referralSource, setReferralSource] = useState('');

    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await api.post('/save-user-details', {
                firstName,
                lastName,
                dob,
                teamSize,
                mainPurpose,
                industry,
                referralSource,
            });

            if (response.data.metadata) {
                const inviteToken = localStorage.getItem('inviteToken');
                if (inviteToken) {
                    navigate(`/invite/${inviteToken}`);
                    localStorage.removeItem('inviteToken');
                } else {
                    navigate('/app');
                }
            }
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to save user details');
        }
    };

    const handleNextStep = () => {
        setError('');
        setStep(step + 1);
    };

    const handlePreviousStep = () => {
        setError('');
        setStep(step - 1);
    };

    return (
        <div className='auth-body'>
            <div className='form-container'>
                <h2 className='login-title'>Complete Your Profile</h2>
                {error && <div style={{ color: 'red' }}>{error}</div>}
                {step === 1 && (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleNextStep();
                        }}
                    >
                        <div>
                            <label>First Name</label>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                placeholder='First Name'
                            />
                        </div>
                        <div>
                            <label>Last Name</label>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                placeholder='Last Name'
                            />
                        </div>
                        <div>
                            <label>Date of Birth</label>
                            <input
                                type="date"
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className='submit-button'>Next</button>
                    </form>
                )}

                {step === 2 && (
                    <form onSubmit={handleSubmit}>
                        <button
                            type="button"
                            className='submit-button'
                            onClick={handlePreviousStep}
                            style={{ marginBottom: '1rem' }}
                        >
                            Back
                        </button>
                        <div>
                            <label>Team Size</label>
                            <div className="team-size-buttons">
                                {['1', '2-10', '11-50', '51-200', '200+'].map((size, index) => (
                                    <button
                                        type="button"
                                        key={size}
                                        className={`team-size-button ${teamSize === index ? 'selected' : ''}`}
                                        onClick={() => setTeamSize(index)}
                                    >
                                        {size}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label>Main Purpose</label>
                            <select value={mainPurpose} onChange={(e) => setMainPurpose(e.target.value)} className='select' required>
                                <option value="">Select Purpose</option>
                                <option value="personal">Personal</option>
                                <option value="business">Business</option>
                                <option value="education">Education</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div>
                            <label>Industry</label>
                            <select value={industry} onChange={(e) => setIndustry(e.target.value)} className='select' required>
                                <option value="">Select Industry</option>
                                <option value="technology">Technology</option>
                                <option value="finance">Finance</option>
                                <option value="healthcare">Healthcare</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div>
                            <label>How Did You Hear About Us?</label>
                            <select value={referralSource} onChange={(e) => setReferralSource(e.target.value)} className='select' required>
                                <option value="">Select Source</option>
                                <option value="social_media">Social Media</option>
                                <option value="friend">Friend</option>
                                <option value="ad">Advertisement</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <button type="submit" className='submit-button'>Continue</button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default UserDetails;