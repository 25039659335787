import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import api from '../../api';
import { useQueryClient } from '@tanstack/react-query';

const GoogleCallback = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    const handleGoogleCallback = async () => {
      try {
        const hash = window.location.hash;
        const params = new URLSearchParams(hash.replace('#', '?'));
        const token = params.get('access_token');
        
        if (!token) throw new Error("No token provided.");

        await api.post('/google-callback', { token });
        
        // Invalidate user query and force refresh
        await queryClient.invalidateQueries(['user']);
        
        navigate('/app');
      } catch (error) {
        navigate('/login');
      }
    };

    handleGoogleCallback();
  }, [navigate, queryClient]);

  return <div>Loading...</div>;
};

export default GoogleCallback;
