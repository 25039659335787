import { QueryClient, useQuery } from '@tanstack/react-query';
import api from '../api';

export const useDashboard = (teamId) => {
    return useQuery({
        queryKey: ['dashboard', teamId],
        queryFn: async () => {
            const { data } = await api.get('/dashboard-info');
            return data;
        },
        select: (data) => {
            // Handle case where data is undefined or teams array is empty
            if (!data || !data.teams || data.teams.length === 0) {
                return {
                    teams: [],
                    selectedTeam: null,
                };
            }

            const selectedTeam = teamId
                ? data.teams.find(t => t.public_id === teamId) || data.teams[0]
                : data.teams[0];

            /*
                if (selectedTeam) {
                    api.post('/update-last-visited-team', { teamId: selectedTeam.public_id })
                        .catch(err => console.error('Failed to update last visited team:', err));
                }
            */

            return {
                ...data,
                selectedTeam,
            };
        },
        staleTime: 1000 * 60 * 5, // 5 minutes cache
        retry: false,
    });
};
