import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import api from '../../api';
import './CreateTeam.css';
import { ArrowLeft, X } from 'phosphor-react';

// Load Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CreateTeam = ({ closeModal }) => {
    const navigate = useNavigate();
    const [section, setSection] = useState(1);
    const [useSelector, setUseSelector] = useState(0);
    const [teamSelector, setTeamSelector] = useState(0);
    const [teamName, setTeamName] = useState('');
    const [pricingOption, setPricingOption] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleNext = () => setSection(section + 1);
    const handlePrevious = () => setSection(section - 1);

    const handlePayment = async () => {
        setLoading(true);
        try {
            const { data } = await api.post('/create-checkout-session', {
                purpose: useSelector,
                usage: teamSelector,
                name: teamName,
                pricing: pricingOption,
            });

            // Redirect to Stripe Checkout
            const stripe = await stripePromise;
            await stripe.redirectToCheckout({ sessionId: data.sessionId });
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleModalContentClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className='modal-overlay' onClick={closeModal}>
            <div className='modal-content' onClick={handleModalContentClick}>
                {section === 1 && (
                    <div className="section">
                        <div className='modal-header'>
                            <h2 className='modal-title'>Team Purpose</h2>
                            <div className='fill' />
                            <X size={20} onClick={closeModal} className='icon interactive' />
                        </div>
                        <p className="subtitle">Help us customize your experience</p>
                        <div className="options">
                            <div className={`option ${useSelector === 1 ? 'selected' : ''}`} onClick={() => setUseSelector(1)}>
                                <h3>For Work</h3>
                                <p className="team-sel-description">Track trends, analyze audience engagement, and optimize content performance for professional growth</p>
                            </div>
                            <div className={`option ${useSelector === 2 ? 'selected' : ''}`} onClick={() => setUseSelector(2)}>
                                <h3>Personal Use</h3>
                                <p className="team-sel-description">Gain insights into your social media habits, manage your presence, and enhance your personal connections</p>
                            </div>
                            <div className={`option ${useSelector === 3 ? 'selected' : ''}`} onClick={() => setUseSelector(3)}>
                                <h3>Other</h3>
                                <p className="team-sel-description">Explore flexible solutions for research, hobbies, or various side projects</p>
                            </div>
                        </div>
                        <button onClick={handleNext} className="submit-button-teamcr" disabled={useSelector === 0}>Next</button>
                    </div>
                )}
                {section === 2 && (
                    <div className="section">
                        <div className='modal-header'>
                            <ArrowLeft size={20} onClick={handlePrevious} className='icon interactive' />
                            <h2 className='modal-title'>Team Usage</h2>
                            <div className='fill' />
                            <X size={20} onClick={closeModal} className='icon interactive' />
                        </div>
                        <p className="subtitle">Help us customize your experience</p>
                        <div className="options-hov">
                            <div className={`option ${teamSelector === 1 ? 'selected' : ''}`} onClick={() => setTeamSelector(1)}>
                                <h3>With Team</h3>
                                <p className="team-sel-description">For team-wide, organization size, and group activities.</p>
                            </div>
                            <div className={`option ${teamSelector === 2 ? 'selected' : ''}`} onClick={() => setTeamSelector(2)}>
                                <h3>Individual</h3>
                                <p className="team-sel-description">Manage and analyze your social media activity independently.</p>
                            </div>
                        </div>
                        <button onClick={handleNext} className="submit-button-teamcr" disabled={teamSelector === 0}>Next</button>
                    </div>
                )}
                {section === 3 && (
                    <div className="section">
                        <div className='modal-header'>
                            <ArrowLeft size={20} onClick={handlePrevious} className='icon interactive' />
                            <h2 className='modal-title'>Team Name</h2>
                            <div className='fill' />
                            <X size={20} onClick={closeModal} className='icon interactive' />
                        </div>
                        <input
                            type="text"
                            placeholder="Enter team name"
                            className='input-create-team-name'
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                        />
                        <button onClick={handleNext} className="submit-button-teamcr" disabled={teamName.trim() === ''}>Next</button>
                    </div>
                )}
                {section === 4 && (
                    <div className="section">
                        <div className='modal-header'>
                            <ArrowLeft size={20} onClick={handlePrevious} className='icon interactive' />
                            <h2 className='modal-title'>Pricing</h2>
                            <div className='fill' />
                            <X size={20} onClick={closeModal} className='icon interactive' />
                        </div>
                        <div className="pricing options">
                            <div className={`option ${pricingOption === 1 ? 'selected' : ''}`} onClick={() => setPricingOption(1)}>
                                <h3>Basic Plan</h3>
                                <p className="team-sel-description">Basic features for individual use.</p>
                            </div>
                        </div>
                        <button onClick={handleNext} className="submit-button-teamcr" disabled={pricingOption === 0}>Next</button>
                    </div>
                )}
                {section === 5 && (
                    <div className="section">
                        <div className='modal-header'>
                            <ArrowLeft size={20} onClick={handlePrevious} className='icon interactive' />
                            <h2 className='modal-title'>Review & Submit</h2>
                            <div className='fill' />
                            <X size={20} onClick={closeModal} className='icon interactive' />
                        </div>
                        <button onClick={handlePayment} className="submit-button-teamcr" disabled={loading}>
                            {loading ? 'Processing...' : 'Proceed to Payment'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreateTeam;