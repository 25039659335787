import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../api';

const Cancel = () => {
    return (
        <div>
            <h1>Payment Cancelled</h1>
            <p>Payment was cancelled. Please try again.</p>
            <button onClick={() => window.location.replace('/')}>Go back to pricing</button>
        </div>
    );
};

export default Cancel;