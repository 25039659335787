import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { House, Calendar, ChartLineUp, Chats, SignOut, Gear, Plus } from 'phosphor-react';
import './SideNav.css';

import { useAuth } from '../../../context/AuthContext';

import loopifyLogo from '../../../assets/brand/loopify.png';

const SideNav = ({ selectedPage, teamId, selectedTeam, toggleDropdown, dropdownActive, postModal }) => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await logout();
        navigate('/');
    };

    const getTeamInitials = (name = '') => {
        const teamName = name.trim() || 'Team';
        const words = teamName.split(' ');

        if (words.length >= 2) {
            return words[0][0].toUpperCase() + words[1][0].toUpperCase();
        }
        return teamName.slice(0, 2).toUpperCase();
    };

    return (
        <div className={`side-nav ${dropdownActive ? 'sel-dd' : ''}`}>
            <div className="nav-logo">
                <span className='nav-text loopify'>l</span>
                <img src={loopifyLogo} alt="Loopify Logo" className="nav-logo-img" />
                <span className='nav-text loopify letterspacing'>pify</span>
            </div>

            <div className={`nav-item post`} onClick={() => postModal(true)}>
                <Plus className="icon" size={24} />
                <span className="nav-text">New Post</span>
            </div>

            {/* Navigation Links */}
            <Link to={`/app/${teamId}/`} className={`nav-item ${selectedPage === '' ? 'selected-page' : '' || selectedPage === 'setup' ? 'selected-page' : '' || selectedPage === 'create-team' ? 'selected-page' : '' || selectedPage === 'overview' ? 'selected-page' : ''}`}>
                <House className="icon" size={24} weight={(selectedPage === 'overview' || selectedPage === 'setup' || selectedPage === 'create-team') ? 'fill' : 'regular'} />
                <span className="nav-text">Overview</span>
            </Link>

            <Link to={`/app/${teamId}/schedule`} className={`nav-item ${selectedPage === 'schedule' ? 'selected-page' : ''}`}>
                <Calendar className="icon" size={24} weight={selectedPage === 'schedule' ? 'fill' : 'regular'} />
                <span className="nav-text">Schedule</span>
            </Link>

            <Link to={`/app/${teamId}/analytics`} className={`nav-item ${selectedPage === 'analytics' ? 'selected-page' : ''}`}>
                <ChartLineUp className="icon" size={24} weight={selectedPage === 'analytics' ? 'fill' : 'regular'} />
                <span className="nav-text">Analytics</span>
            </Link>

            <Link to={`/app/${teamId}/engagement`} className={`nav-item ${selectedPage === 'engagement' ? 'selected-page' : ''}`}>
                <Chats className="icon" size={24} weight={selectedPage === 'engagement' ? 'fill' : 'regular'} />
                <span className="nav-text">Engagement</span>
            </Link>

            <div className="bottom-nav"></div>

            <Link to={`/app/${teamId}/settings`} className={`nav-item ${selectedPage === 'settings' ? 'selected-page' : ''}`}>
                <Gear className="icon" size={24} weight={selectedPage === 'settings' ? 'fill' : 'regular'} />
                <span className="nav-text">Settings</span>
            </Link>

            <div className={`nav-item`} onClick={handleLogout}>
                <SignOut className="icon" size={24} />
                <span className="nav-text">Logout</span>
            </div>

            {selectedTeam && (
                <>
                    <div className="menu-divider" />

                    <div className={`nav-item-logo`} onClick={toggleDropdown}>
                        {
                            selectedTeam?.icon_url ? (
                                <img src={selectedTeam?.icon_url} alt="Profile" className="icon team-picture menu-size-picture" />
                            ) : (
                                <div
                                    className='fallback-profile icon team-picture menu-size-picture'
                                    style={{ backgroundColor: selectedTeam?.fallback_colour }}
                                >
                                    <p>{getTeamInitials(selectedTeam?.team_name)}</p>
                                </div>
                            )
                        }
                        
                        <span className="nav-text team-side-bar">
                            <p className='subtitle-mini'>
                                Team
                            </p>
                            <p className='side-bar-team-name'>
                                {selectedTeam?.team_name}
                            </p>
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default SideNav;