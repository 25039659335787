import React from 'react';
import { CaretUp, CaretDown } from 'phosphor-react';

const youtubeCategories = [
    { value: "1", label: "Film & Animation" },
    { value: "2", label: "Autos & Vehicles" },
    { value: "10", label: "Music" },
    { value: "15", label: "Pets & Animals" },
    { value: "17", label: "Sports" },
    { value: "18", label: "Short Movies" },
    { value: "19", label: "Travel & Events" },
    { value: "20", label: "Gaming" },
    { value: "21", label: "Videoblogging" },
    { value: "22", label: "People & Blogs" },
    { value: "23", label: "Comedy" },
    { value: "24", label: "Entertainment" },
    { value: "25", label: "News & Politics" },
    { value: "26", label: "Howto & Style" },
    { value: "27", label: "Education" },
    { value: "28", label: "Science & Technology" },
    { value: "29", label: "Nonprofits & Activism" },
    { value: "30", label: "Movies" },
    { value: "31", label: "Anime/Animation" },
    { value: "32", label: "Action/Adventure" },
    { value: "33", label: "Classics" },
    { value: "34", label: "Comedy" },
    { value: "35", label: "Documentary" },
    { value: "36", label: "Drama" },
    { value: "37", label: "Family" },
    { value: "38", label: "Foreign" },
    { value: "39", label: "Horror" },
    { value: "40", label: "Sci-Fi/Fantasy" },
    { value: "41", label: "Thriller" },
    { value: "42", label: "Shorts" },
    { value: "43", label: "Shows" },
    { value: "44", label: "Trailers" }
];

export const renderPlatformSettings = (platform, platformSettings, handlePlatformSettingChange) => {
    switch (platform) {
        case 'tiktok':

            return (
                <div className="platform-settings-content">
                    <div className='platform-setting-row'>
                        <div className="platform-setting">
                            <label>Privacy</label>
                            <select
                                value={platformSettings.tiktok.category}
                                onChange={(e) => handlePlatformSettingChange('tiktok', 'privacy', e.target.value)}
                            >
                                <option value="PUBLIC_TO_EVERYONE">Public</option>
                                <option value="MUTUAL_FOLLOW_FRIENDS">Mutual Followers</option>
                                <option value="FOLLOWER_OF_CREATOR">Followers</option>
                                <option value="SELF_ONLY">Private</option>
                            </select>
                        </div>
                    </div>
                    <div className='platform-setting-row'>
                        <div className="platform-setting">
                            <div className='platform-setting-item'>
                                <label>Disable Duet</label>
                                <input
                                    type="checkbox"
                                    checked={platformSettings.tiktok.disableDuet}
                                    onChange={(e) => handlePlatformSettingChange('tiktok', 'disableDuet', e.target.checked)}
                                />
                            </div>
                        </div>
                        <div className="platform-setting">

                            <div className='platform-setting-item'>
                                <label>Disable Stitch</label>
                                <input
                                    type="checkbox"
                                    checked={platformSettings.tiktok.disableStitch}
                                    onChange={(e) => handlePlatformSettingChange('tiktok', 'disableStitch', e.target.checked)}
                                />
                            </div>
                        </div>

                        <div className='platform-setting'>
                            <div className='platform-setting-item'>
                                <label>Disable Comments</label>
                                <input
                                    type="checkbox"
                                    checked={platformSettings.tiktok.disableComments}
                                    onChange={(e) => handlePlatformSettingChange('tiktok', 'disableComments', e.target.checked)}
                                />
                            </div>
                        </div>
                    </div>

                    <p className='settings-seperate'>Advanced Settings</p>

                    <div className='platform-setting-row'>
                        <div className="platform-setting">
                            <div className='platform-setting-item'>
                                <label>Branded Content</label>
                                <input
                                    type="checkbox"
                                    checked={platformSettings.tiktok.brandedContent}
                                    onChange={(e) => handlePlatformSettingChange('tiktok', 'brandedContent', e.target.checked)}
                                />
                            </div>
                        </div>
                        <div className="platform-setting">
                            <div className='platform-setting-item'>
                                <label>Own Brand</label>
                                <input
                                    type="checkbox"
                                    checked={platformSettings.tiktok.organicBrand}
                                    onChange={(e) => handlePlatformSettingChange('tiktok', 'organicBrand', e.target.checked)}
                                />
                            </div>
                        </div>
                        <div className="platform-setting">
                            <div className='platform-setting-item'>
                                <label>AI Content</label>
                                <input
                                    type="checkbox"
                                    checked={platformSettings.tiktok.allowDownloads}
                                    onChange={(e) => handlePlatformSettingChange('tiktok', 'is_aigc', e.target.checked)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );

        case 'youtube':
            return (
                <div className="platform-settings-content">
                    <div className='platform-setting-row'>
                        <div className="platform-setting">
                            <label>Category</label>
                            <select
                                value={platformSettings.youtube.category}
                                onChange={(e) => handlePlatformSettingChange('youtube', 'category', e.target.value)}
                            >
                                {youtubeCategories.map(category => (
                                    <option key={category.value} value={category.value}>{category.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="platform-setting">
                            <label>Privacy</label>
                            <select
                                value={platformSettings.youtube.privacy}
                                onChange={(e) => handlePlatformSettingChange('youtube', 'privacy', e.target.value)}
                            >
                                <option value="public">Public</option>
                                <option value="unlisted">Unlisted</option>
                                <option value="private">Private</option>
                            </select>

                        </div>


                    </div>
                    <div className='platform-setting-row'>
                        <div className="platform-setting">
                            <label>Video Tags</label>
                            <input
                                type="text"
                                value={platformSettings.youtube.tags}
                                onChange={(e) => handlePlatformSettingChange('youtube', 'tags', e.target.value)}
                                placeholder="Enter tags separated by commas"
                            />
                        </div>
                    </div>

                    <p className='settings-seperate'>Advanced Settings</p>
                    <div className='platform-setting-row'>
                        <div className="platform-setting">
                            <div className='platform-setting-item'>
                                <label>Made for Kids</label>
                                <input
                                    type="checkbox"
                                    checked={platformSettings.youtube.madeForKids}
                                    onChange={(e) => handlePlatformSettingChange('youtube', 'madeForKids', e.target.checked)}
                                />
                            </div>
                        </div>
                        <div className="platform-setting">
                            <div className='platform-setting-item'>
                                <label>AI Content</label>
                                <input
                                    type="checkbox"
                                    checked={platformSettings.youtube.is_aigc}
                                    onChange={(e) => handlePlatformSettingChange('youtube', 'is_aigc', e.target.checked)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );

        default:
            return null;
    }
};

export const PlatformSettingsWrapper = ({
    platformPage,
    selectedPlatforms,
    expandedPlatforms,
    togglePlatformSettings,
    platformSettings,
    handlePlatformSettingChange
}) => (
    <div className='platform-changes-settings'>
        {platformPage === 'all' ? (
            selectedPlatforms.map(platform => (
                <div key={platform} className="platform-settings-item">
                    <div
                        className="platform-settings-header"
                        onClick={() => togglePlatformSettings(platform)}
                    >
                        <span>{platform.charAt(0).toUpperCase() + platform.slice(1)}</span>
                        {expandedPlatforms.includes(platform) ? <CaretUp size={18} /> : <CaretDown size={18} />}
                    </div>
                    {expandedPlatforms.includes(platform) && (
                        renderPlatformSettings(platform, platformSettings, handlePlatformSettingChange)
                    )}
                </div>
            ))
        ) : (
            <div className="platform-settings-item expanded">
                <div className="platform-settings-header">
                    {platformPage.charAt(0).toUpperCase() + platformPage.slice(1)} Settings
                </div>
                {renderPlatformSettings(platformPage, platformSettings, handlePlatformSettingChange)}
            </div>
        )}
    </div>
);