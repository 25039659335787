import React, { useEffect } from 'react';
import { useAuth } from '../../context/AuthContext'; // Use AuthContext for auth state
import Navbar from '../../components/Navbar';
import './Home.css';

const Home = () => {
    const { isAuthenticated, needsMetadata } = useAuth();

    useEffect(() => {
        console.log('Home isAuthenticated:', isAuthenticated); // Debugging
        console.log('Home needsMetadata:', needsMetadata); // Debugging
    }, [isAuthenticated, needsMetadata]);


    localStorage.removeItem('inviteToken');
  
    return (
      <div>
        <Navbar isLoggedIn={isAuthenticated || needsMetadata} />
        <div className="home-container">
          <div className="hero-section">
            <h1>Loopify your life</h1>
            <p>All your content in one place</p>
          </div>
        </div>
      </div>
    );
  };

export default Home;