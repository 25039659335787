import React, { useState, useRef, useEffect } from 'react';
import { X, Plus, Camera, Pencil, Trash, ArrowUpRight } from 'phosphor-react';
import { useParams } from 'react-router-dom';
import { useDashboard } from '../../../../hooks/useDashboard';
import api from '../../../../api';


import YoutubeLogo from '../../../../assets/logos/youtube.png';
import TiktokLogo from '../../../../assets/logos/tiktok.png';

import './Channels-Brands.css';
import { useQueryClient } from '@tanstack/react-query';

const ChannelsBrands = () => {
    const { teamId } = useParams();
    const queryClient = useQueryClient();

    const { data, refetch } = useDashboard(teamId);
    const { teams, selectedTeam } = data;

    const [isAddOpen, setIsAddOpen] = useState(false);

    const [isEditOpen, setIsEditOpen] = useState(false);
    const [editData, setEditData] = useState(null);

    const [isMoveOpen, setIsMoveOpen] = useState(false);

    const [isDragging, setIsDragging] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [brandName, setBrandName] = useState('');
    const fileInputRef = useRef(null);

    const [brands, setBrands] = useState(selectedTeam.brands);

    const [openDropdown, setOpenDropdown] = useState(null);
    const [editDropdown, setEditDropdown] = useState(null);
    const [moveDropdown, setMoveDropdown] = useState(null);
    const modalContentRef = useRef(null);

    const getTeamInitials = (name = '') => {
        const teamName = name.trim() || 'Team';
        const words = teamName.split(' ');

        if (words.length >= 2) {
            return words[0][0].toUpperCase() + words[1][0].toUpperCase();
        }
        return teamName.slice(0, 2).toUpperCase();
    };

    useEffect(() => {
        //update info when data changes
        setBrands(selectedTeam.brands);
    }, [data]);

    const handleEditOpen = (brand) => {
        setEditData(brand);
        setBrandName(brand.brand_name);
        setIsEditOpen(true);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openDropdown !== null) {
                const isDropdown = event.target.closest('.dropdown-menu');
                const isAddButton = event.target.closest('.add-channel-button');

                console.log(isDropdown, isAddButton);

                if (!isDropdown && !isAddButton) {
                    setOpenDropdown(null);
                }
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [openDropdown]);

    useEffect(() => {
        if (isEditOpen || isMoveOpen) {
            const handleClickInsideModal = (event) => {
                if (editDropdown !== null || moveDropdown !== null) {
                    const isDropdown = event.target.closest('.dropdown-menu-edit');
                    const isAddButton = event.target.closest('.add-channel-button');

                    if (!isDropdown && !isAddButton) {
                        setEditDropdown(null);
                        setMoveDropdown(null);
                    }
                }
            };

            const modalContent = modalContentRef.current;
            if (modalContent) {
                modalContent.addEventListener('click', handleClickInsideModal);
                return () => {
                    modalContent.removeEventListener('click', handleClickInsideModal);
                }
            }
        }
    }, [isEditOpen, editDropdown, isMoveOpen, moveDropdown]);


    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) handleFile(file);
    };

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        if (file) handleFile(file);
    };

    const handleFile = (file) => {
        setSelectedFile(file);
    };

    const clearInputs = () => {
        setSelectedFile(null);
        setBrandName('');
    };

    const handleCloseModal = () => {
        setIsAddOpen(false);
        setIsEditOpen(false);
        setEditDropdown(null);
        clearInputs();
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('brandImage', selectedFile);
        formData.append('brandName', brandName);

        try {
            // Add brand
            await api.post(`${teamId}/add-brand`, formData);
            handleCloseModal();
        } catch (error) {
            console.error('Error adding brand:', error);
        }
    };

    const handleEdit = async () => {
        const formData = new FormData();
        formData.append('brandImage', selectedFile);
        formData.append('brandName', brandName);
        formData.append('brandId', editData.brand_id);

        console.log('Edit data:', formData);
        console.log(selectedFile);

        //check if changed image and or name
        if (!selectedFile && brandName === editData.brand_name) {
            handleCloseModal();
            return;
        }

        console.log('Edit data:', formData);

        try {
            // Edit brand
            await api.post(`${teamId}/edit-brand`, formData);
            handleCloseModal();
        } catch (error) {
            console.error('Error editing brand:', error
            );
        }
    }

    const handleYoutube = async (brandId) => {
        try {
            const response = await api.post(
                `${teamId}/youtube-auth`,
                { brandId },
                { withCredentials: true }
            );
            window.location.href = response.data.authUrl;
        } catch (error) {
            console.error('YouTube auth failed:', error);
            // Handle error in UI
        }
    };

    const handleTiktok = async (brandId) => {
        try {
            const response = await api.post(
                `${teamId}/tiktok-auth`,
                { brandId },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            // Open authorization in same tab to preserve cookies
            window.location.href = response.data.authUrl;
        } catch (error) {
            console.error('TikTok auth failed:', error);
        }
    };

    const moveChannel = async (channelId, brandId) => {
        try {
            await api.post(`${teamId}/move-channel`, {
                channelId,
                brandId
            });

            refetch();
            handleCloseModal();
        } catch (error) {
            console.error('Error moving channel:', error);
        }
    }

    const removeSocial = async (socialId) => {
        try {
            await api.post(`${teamId}/delete-channel`, {
                socialId
            });

            refetch();
            handleCloseModal();
        } catch (error) {
            console.error('Error removing social:', error);
        }
    };

    const removeBrand = async (brandId) => {
        try {
            await api.post(`${teamId}/delete-brand`, {
                brandId
            });

            refetch();
        } catch (error) {
            console.error('Error removing brand:', error);
        }
    }

    const getPlatformLogo = (platform) => {
        switch (platform) {
            case 'youtube':
                return YoutubeLogo;
            case 'tiktok':
                return TiktokLogo;
            default:
                return '';
        }
    };

    return (
        <div className='settings-body-sub'>
            <div className='title-settings'>
                <h2>Brands & Channels</h2>
                <div className='fill' />
                <div className='add-channel-button' onClick={() => setIsAddOpen(true)}>
                    <Plus size={15} className='icon' />
                    <p>New Brand</p>
                </div>
            </div>
            <div className='dynamic-main-settings'>
                <div className='main-content-setting scrollable'>
                    {/* Brand card in the main render */}
                    {brands.map((brand) => (
                        <div key={brand.brand_id} className='brand-card frosted'>
                            <div className='brand-card-header'>
                                {
                                    brand?.image_url ? (
                                        <img src={brand?.image_url} alt="Profile" className="icon team-picture menu-size-picture" />
                                    ) : (
                                        <div
                                            className='fallback-profile icon team-picture menu-size-picture'
                                            style={{ backgroundColor: selectedTeam?.fallback_colour }}
                                        >
                                            <p>{getTeamInitials(brand?.brand_name)}</p>
                                        </div>
                                    )
                                }
                                <h3>{brand.brand_name}</h3>
                                <div className='fill' />
                                <div className='add-channel-button' onClick={() => setOpenDropdown(brand.brand_id)}>
                                    <Plus size={14} />
                                    Add Channel
                                </div>
                                <Pencil size={20} className='icon interactive' onClick={() => handleEditOpen(brand)} />
                                <Trash size={20} className='icon interactive' onClick={() => removeBrand(brand.brand_id)} color='red' />
                            </div>

                            {openDropdown === brand.brand_id && (
                                <div className='dropdown-menu'>
                                    <div className='dropdown-item' onClick={() => handleYoutube(brand.brand_id)}>
                                        YouTube
                                    </div>
                                    <div className='dropdown-item' onClick={() => handleTiktok(brand.brand_id)}>
                                        Tiktok
                                    </div>
                                </div>
                            )}

                            {brand.socials.length > 0 && (
                                <>
                                    <div className='channel-list-divider' />
                                    <div className='channel-list'>
                                        {brand.socials.map((social) => (
                                            <div key={social.social_id} className='channel-card'>
                                                <img src={getPlatformLogo(social.type)} alt="Platform" className='platform-logo img-very-mini' />
                                                <p className="channel-title">{social.channel_title}</p>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {isEditOpen && (
                <div className="modal-overlay" onClick={handleCloseModal}>
                    <div
                        className="modal-content"
                        onClick={(e) => e.stopPropagation()}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        ref={modalContentRef}
                    >
                        <div className='modal-header'>
                            <h2>Edit Brand</h2>
                            <div className='fill' />
                            <X size={20} onClick={handleCloseModal} className='icon interactive' />
                        </div>
                        <div className='divider' />

                        <div className='upload-section'>

                            <div className={`upload-area ${isDragging ? 'dragging' : ''}`}
                                onClick={() => fileInputRef.current.click()}>
                                <div
                                    className="upload-content"
                                >
                                    {editData.image_url ? (
                                        selectedFile ? (
                                            <p>{selectedFile.name}</p>
                                        ) : (
                                            <img src={editData.image_url} alt="Brand" />
                                        )
                                    ) : (
                                        <Camera size={24} />
                                    )}
                                </div>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileInput}
                                    accept="image/png, image/jpeg"
                                    hidden
                                />
                            </div>

                            <p className='upload-subtitle'>Maximum image size: 10MB Supported formats: PNG, JPG</p>

                        </div>

                        <div className="brand-name-input">
                            <h4>Brand Name</h4>
                            <input
                                type="text"
                                value={brandName}
                                onChange={(e) => setBrandName(e.target.value)}
                                placeholder="Enter brand name"
                            />
                        </div>

                        <div className='modal-main-list'>
                            <div className='edit-channel-header'>
                                <h4>Channels</h4>
                                <div className='fill' />

                                {editDropdown === editData.brand_id && (
                                    <div className='dropdown-menu-edit NC-Shift'>
                                        <div className='dropdown-item' onClick={() => handleYoutube(editData.brand_id)}>
                                            YouTube
                                        </div>
                                        <div className='dropdown-item' onClick={() => handleTiktok(editData.brand_id)}>
                                            Tiktok
                                        </div>
                                    </div>
                                )}

                                <div className='add-channel-button' onClick={() => setEditDropdown(editData.brand_id)}>
                                    <Plus size={15} className='icon' />
                                    <p>New Channel</p>
                                </div>
                            </div>
                            <div className='edit-channel-list'>
                                {editData.socials.map((social) => (
                                    <div key={social.social_id} className='channel-card-edit'>
                                        <div className='edit-card-header'>
                                            <p className="channel-type-indicator">{social.type[0]}</p>
                                            <p className="channel-title">@{social.channel_title}</p>
                                            <div className='fill' />

                                            {moveDropdown === social.id && (
                                                <div className='dropdown-menu-edit'>
                                                    {
                                                        brands.map((brand) => (
                                                            <div key={brand.brand_id} className='dropdown-item' onClick={() => moveChannel(social.id, brand.brand_id)}>
                                                                {brand.brand_name}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )}

                                            <button className='move-edit-button' onClick={() => setMoveDropdown(social.id)}>
                                                <ArrowUpRight size={18} />
                                                Move
                                            </button>
                                            <button className='delete-edit-button' onClick={() => removeSocial(social.id)}>
                                                <Trash size={18} />
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='fill' />

                        <div className="modal-footer">
                            <button
                                className="done-button"
                                onClick={handleEdit}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isAddOpen && (
                <div className="modal-overlay" onClick={handleCloseModal}>
                    <div
                        className="modal-content"
                        onClick={(e) => e.stopPropagation()}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <div className='modal-header'>
                            <h2>Add Brand</h2>
                            <div className='fill' />
                            <X size={20} onClick={handleCloseModal} className='icon interactive' />
                        </div>
                        <div className='divider' />

                        <div className='upload-section'>

                            <div className={`upload-area ${isDragging ? 'dragging' : ''}`}

                                onClick={() => fileInputRef.current.click()}>
                                <div
                                    className="upload-content"
                                >
                                    {selectedFile ? (
                                        <p>{selectedFile.name}</p>
                                    ) : (
                                        <>
                                            <Camera size={24} />
                                        </>
                                    )}
                                </div>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileInput}
                                    accept="image/png, image/jpeg"
                                    hidden
                                />
                            </div>

                            <p className='upload-subtitle'>Maximum image size: 10MB Supported formats: PNG, JPG</p>

                        </div>

                        <div className="brand-name-input">
                            <h4>Brand Name</h4>
                            <input
                                type="text"
                                value={brandName}
                                onChange={(e) => setBrandName(e.target.value)}
                                placeholder="Enter brand name"
                            />
                        </div>

                        <div className="modal-footer">
                            <button
                                className="done-button"
                                onClick={handleSubmit}
                                disabled={!selectedFile || !brandName}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChannelsBrands;