import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Auth.css';
import api from '../../api';
import { useAuth } from '../../context/AuthContext';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [step, setStep] = useState(1);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const navigate = useNavigate();

    const { user, isAuthenticated, isLoading, error: authError, needsMetadata } = useAuth(); // Destructure correctly

    useEffect(() => {
        if (isAuthenticated) {
            if (needsMetadata) {
                navigate('/complete-account');
            } else {
                navigate('/app');
            }
        }
    }, [user, isAuthenticated, needsMetadata, authError, navigate]);

    const handleEmailCheck = async (e) => {
        e.preventDefault();

        try {
            const response = await api.post('/check-email', { email });
            if (response.data.exists === false) {
                handleNextStep();
                setError('');
            } else {
                setError('Email already exists');
            }
        } catch (err) {
            setError(err.response?.data?.error || 'Email check failed');
        }
    };

    const handleSignup = async (e) => {
        e.preventDefault();

        try {
            const response = await api.post('/signup', {
              email,
              password,
            });
        
            localStorage.setItem('token', response.data.token);
            const inviteToken = localStorage.getItem('inviteToken');
            
            if (inviteToken) {
              navigate(`/invite/${inviteToken}`);
              localStorage.removeItem('inviteToken');
            } else {
              navigate('/complete-account');
            }
          } catch (err) {
            setError(err.response?.data?.error || 'Signup failed');
          }
    };

    const googleLogin = async () => {
        try {
            // Make POST request to backend
            const response = await api.post(
                '/google-login',
                {},
                { withCredentials: true },
            );
            window.location.href = response.data.url;
        } catch (err) {
            // Handle errors (e.g., invalid credentials)
            setError(err.response?.data?.error || 'Login failed');
        }
    }

    const calculatePasswordStrength = (password) => {
        let strength = 0;

        if (password.length >= 8) strength++;
        if (/[A-Z]/.test(password)) strength++;
        if (/[0-9]/.test(password)) strength++;
        if (/[^A-Za-z0-9]/.test(password)) strength++;

        setPasswordStrength(Math.min(Math.max(strength, 0), 4));
    };

    const handleNextStep = () => {
        setError('');
        setStep(step + 1);
    };

    const handlePreviousStep = () => {
        setError('');
        setStep(step - 1);
    };

    return (
        <div className='auth-body'>
            <div className='form-container'>
                <h2 className='login-title'>Sign up</h2>
                {error && <div style={{ color: 'red' }}>{error}</div>}
                {step === 1 && (
                    <form onSubmit={handleEmailCheck}>
                        <div>
                            <button type="button" className="google-button" onClick={googleLogin}>
                                <img src="https://img.icons8.com/color/48/000000/google-logo.png" alt="Google Logo" height={25} />
                                Sign in with Google
                            </button>

                            <p className='or'>or</p>
                            <label className='label-login'>Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                placeholder='Email'
                            />
                            <div>
                                <br />
                            </div>
                            <div>
                                <span className='label-login'>Already have an account? </span>
                                <a href='/login' className='label-login'>Login here</a>
                            </div>
                        </div>
                        <button type="submit" className='submit-button'>Next</button>
                    </form>
                )}

                {step === 2 && (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (passwordStrength >= 2) handleSignup(e);
                            else setError('Password must be at least medium strength.');
                        }}
                    >
                        <button
                            type="button"
                            className='submit-button'
                            onClick={handlePreviousStep}
                            style={{ marginBottom: '1rem' }}
                        >
                            Back
                        </button>
                        <div>
                            <label>Password</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    calculatePasswordStrength(e.target.value);
                                }}
                                required
                                placeholder='Password'
                            />
                            <p>
                                Password strength: <span>{['Weak', 'Medium', 'Strong', 'Very Strong'][passwordStrength]}</span>
                            </p>
                        </div>
                        <div className="password-strength">
                            {[...Array(4)].map((_, index) => (
                                <div
                                    key={index}
                                    className={`strength-bar ${passwordStrength >= index + 1 ? 'filled' : ''}`}
                                    style={{
                                        backgroundColor:
                                            passwordStrength >= index + 1
                                                ? passwordStrength === 1
                                                    ? 'red'
                                                    : passwordStrength === 2
                                                        ? 'orange'
                                                        : 'green'
                                                : '#e0e0e0',
                                    }}
                                ></div>
                            ))}
                        </div>
                        <div>
                            <label>Repeat Password</label>
                            <input
                                type="password"
                                required
                                placeholder='Repeat Password'
                            />
                        </div>
                        <button type="submit" className='submit-button'>Sign Up</button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default Signup;
