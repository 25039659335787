import React, { use, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ isLoggedIn }) => {
    useEffect(() => {
        console.log('Navbar isLoggedIn:', isLoggedIn); // Debugging
    }, [isLoggedIn]);

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <h1 className="logo">
                    <Link to="/">loopify</Link>
                </h1>
                <div className="nav-links">
                    <Link to="#features" className="nav-link">Features</Link>
                    <Link to="/blog" className="nav-link">About</Link>
                    <Link to="/pricing" className="nav-link">Pricing</Link>
                    {isLoggedIn ? (
                        <Link to="/app" className="dashboard-button-home">Dashboard</Link>
                    ) : (
                        <>
                            <Link to="/login" className="nav-link login-button-home">Login</Link>
                            <Link to="/signup" className="nav-link signup-button-home">Signup</Link>
                        </>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;