import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { Analytics } from "@vercel/analytics/react"

// Pages
import Home from './pages/Home/Home';
import Login from './pages/Auth/Login';
import Signup from './pages/Auth/Signup';
import GoogleCallback from './pages/Auth/Callback';
import Dashboard from './pages/Dashboard/Dashboard';
import CreateTeam from './pages/Team/CreateTeam';
import Success from './pages/Payment/Success';
import Cancel from './pages/Payment/Cancel';
import UserDetails from './pages/Auth/UserDetails';
import InviteHandler from './pages/Auth/InviteHandler';

import Logout from './pages/Auth/Logout';

import { ThemeProvider } from './theme-context';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Analytics />
      <AuthProvider>
        <ThemeProvider>
          <Router>
            <Routes>
              {/* Home Routes */}
              <Route path="/" element={<Home />} />

              {/* Auth Routes */}
              <Route path="/logout" element={<Logout />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/complete-account" element={<UserDetails />} />
              <Route path="/auth/callback" element={<GoogleCallback />} />

              <Route path='/invite/:token' element={<InviteHandler />} />

              {/* Dashboard Routes */}
              <Route path="/app/:teamId/*" element={<Dashboard />} />
              <Route path="/app/*" element={<Dashboard />} />

              {/* Payment Routes */}
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />

              {/* 404 Route */}
              <Route path="*" element={<p>404 lol</p>} />
            </Routes>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;