import React, { createContext, useContext, useEffect, useState } from 'react';
import { useUser } from '../hooks/useUser';
import { useQueryClient } from '@tanstack/react-query'; // Import useQueryClient
import api from '../api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const { data: user, isLoading, error, refetch } = useUser();
  const [previousUser, setPreviousUser] = useState(null);
  const queryClient = useQueryClient(); // Get the app's QueryClient

  useEffect(() => {
    if (user !== undefined) setPreviousUser(user);
  }, [user]);

  const logout = async () => {
    try {
      await api.post('/logout');
      queryClient.removeQueries(['user']);
      await refetch();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const value = {
    user: isLoading ? previousUser : user,
    isAuthenticated: !!user,
    isLoading,
    error: error,
    needsMetadata: user?.needsMetadata || false,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);