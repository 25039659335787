import React, { useState } from 'react';
import { formatNumber } from '../../../../utils';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

const geoUrl = "https://raw.githubusercontent.com/nvkelso/natural-earth-vector/master/geojson/ne_110m_admin_0_countries.geojson";

// Define these outside the component as they are static
const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

const getFlagEmoji = (countryCode) => {
    if (!countryCode || countryCode.length !== 2) return '';
    const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
};

const getFillColor = (percentage) => {
    if (percentage === 0) return '#afafaf';
    const minColor = [175, 175, 190];
    const maxColor = [0, 0, 255];
    const factor = Math.min(Math.max(percentage, 0), 100) / 100;
    const r = Math.round(minColor[0] + (maxColor[0] - minColor[0]) * factor);
    const g = Math.round(minColor[1] + (maxColor[1] - minColor[1]) * factor);
    const b = Math.round(minColor[2] + (maxColor[2] - minColor[2]) * factor);
    return `rgb(${r}, ${g}, ${b})`;
};

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

const DemographicsAnalytics = ({ data }) => {
    const [currentView, setCurrentView] = useState('world');

    const viewConfigs = {
        world: { center: [0, 45], scale: 100 },
        africa: { center: [20, 0], scale: 250 },
        asia: { center: [90, 30], scale: 250 },
        europe: { center: [10, 50], scale: 400 },
        northAmerica: { center: [-100, 40], scale: 250 },
        southAmerica: { center: [-60, -20], scale: 200 },
        oceania: { center: [140, -30], scale: 300 },
    };

    const viewOptions = [
        { value: 'world', label: 'World' },
        { value: 'africa', label: 'Africa' },
        { value: 'asia', label: 'Asia' },
        { value: 'europe', label: 'Europe' },
        { value: 'northAmerica', label: 'North America' },
        { value: 'southAmerica', label: 'South America' },
        { value: 'oceania', label: 'Oceania' },
    ];

    // Data calculations
    // Calculate total views across all items
    const totalViews = data.reduce((acc, item) => acc + (item.views || 0), 0);

    // Geographic data: Use views to calculate percentages
    const geographicData = data.flatMap(item => item.demographics?.geographic || []);
    const geoTotalViews = geographicData.reduce((acc, country) => acc + (country.views || 0), 0);

    const countryViews = geographicData.reduce((acc, { country, views }) => {
        acc[country] = (acc[country] || 0) + (views || 0);
        return acc;
    }, {});
    const countryViewPercentages = Object.entries(countryViews).reduce((acc, [country, views]) => {
        acc[country] = geoTotalViews ? (views / geoTotalViews) * 100 : 0;
        return acc;
    }, {});

    // Gender data: Use viewerPercentage weighted by item views
    const genderTotalViews = {};
    data.forEach(item => {
        const itemViews = item.views || 0;
        const genderDemographics = item.demographics?.gender || [];
        genderDemographics.forEach(({ gender, viewerPercentage }) => {
            const genderViews = itemViews * ((viewerPercentage || 0) / 100);
            genderTotalViews[gender] = (genderTotalViews[gender] || 0) + genderViews;
        });
    });
    const genderPercentages = Object.entries(genderTotalViews).reduce((acc, [gender, views]) => {
        acc[gender] = totalViews ? (views / totalViews) * 100 : 0;
        return acc;
    }, {});

    const deviceTypeTotalViews = {};
    data.forEach(item => {
        const itemViews = item.views || 0;
        const deviceTypeDemographics = item.demographics?.deviceType || [];
        deviceTypeDemographics.forEach(({ deviceType, views }) => {
            deviceTypeTotalViews[deviceType] = (deviceTypeTotalViews[deviceType] || 0) + views;
        });
    });
    const deviceTypePercentages = Object.entries(deviceTypeTotalViews).reduce((acc, [deviceType, views]) => {
        acc[deviceType] = totalViews ? (views / totalViews) * 100 : 0;
        return acc;
    }, {});

    // Age group data: Use viewerPercentage weighted by item views
    const ageGroupTotalViews = {};
    data.forEach(item => {
        const itemViews = item.views || 0;
        const ageDemographics = item.demographics?.ageGroups || [];
        ageDemographics.forEach(({ ageGroup, viewerPercentage }) => {
            const ageViews = itemViews * ((viewerPercentage || 0) / 100);
            ageGroupTotalViews[ageGroup] = (ageGroupTotalViews[ageGroup] || 0) + ageViews;
        });
    });
    const ageGroupPercentages = Object.entries(ageGroupTotalViews).reduce((acc, [ageGroup, views]) => {
        acc[ageGroup] = totalViews ? (views / totalViews) * 100 : 0;
        return acc;
    }, {});

    return (
        <div className="analytics-section">
            <div className="analytics-demo-map frosted">
                <ComposableMap
                    projection="geoMercator"
                    projectionConfig={viewConfigs[currentView]}
                    height={420}
                    style={{ width: '100%', height: '100%' }}
                >
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies
                                .filter(geo => geo.properties.ISO_A2 !== 'AQ')
                                .map((geo) => {
                                    const countryCode = geo.properties.ISO_A2;
                                    const percentage = countryViewPercentages[countryCode] || 0;
                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            fill={getFillColor(percentage)}
                                            stroke="#ffffff"
                                            strokeWidth={0.2}
                                            style={{
                                                default: { outline: 'none' },
                                                hover: { outline: 'none' },
                                                pressed: { outline: 'none' },
                                            }}
                                        />
                                    );
                                })
                        }
                    </Geographies>
                </ComposableMap>
                <div className="viewSelector">
                    {viewOptions.map(option => (
                        <div
                            key={option.value}
                            className={`view-sel-item ${currentView === option.value ? 'active' : ''}`}
                            onClick={() => setCurrentView(option.value)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
            <div className="analytics-demo-grid">
                {/* Column 1: Tall then Short */}
                <div className="grid-column">
                    <div className="grid-item frosted tall">
                        <h3>Top 5 Countries by Views</h3>
                        {Object.entries(countryViewPercentages)
                            .sort((a, b) => b[1] - a[1])
                            .slice(0, 5)
                            .map(([country, percentage]) => (
                                <div key={country} className="country-view-item">
                                    <span className="country-flag" style={{ fontSize: '1.5em' }}>
                                        {getFlagEmoji(country)}
                                    </span>
                                    <span className="country-name">
                                        {regionNames.of(country)}
                                    </span>
                                    <div className="fill" />
                                    <span className="country-percentage">
                                        {percentage.toFixed(2)}%
                                    </span>
                                </div>
                            ))}
                    </div>
                    <div className="grid-item frosted short">
                        <h3>Device Types</h3>
                        {Object.entries(deviceTypePercentages)
                            .sort(([, aPercent], [, bPercent]) => bPercent - aPercent)
                            .map(([deviceType, percentage]) => (
                                <div key={deviceType} className="device-type-item">
                                    <span>{capitalize(deviceType)}</span>
                                    <div className="fill" />
                                    <span>{percentage.toFixed(2)}%</span>
                                </div>
                            ))}
                    </div>
                </div>
                {/* Column 2: Short then Tall */}
                <div className="grid-column">
                    <div className="grid-item frosted short">
                        <h3>Gender Distribution</h3>
                        {Object.entries(genderPercentages)
                            .sort(([, aPercent], [, bPercent]) => bPercent - aPercent)
                            .map(([gender, percentage]) => (
                                <div key={gender} className="gender-item">
                                    <span>{capitalize(gender)}</span>
                                    <div className="fill" />
                                    <span>{percentage.toFixed(2)}%</span>
                                </div>
                            ))}
                    </div>
                    <div className="grid-item frosted tall">
                        <h3>Age Group Distribution</h3>
                        {Object.entries(ageGroupPercentages)
                            .map(([ageGroup, percentage]) => (
                                <div key={ageGroup} className="age-group-item">
                                    <span>{ageGroup}</span>
                                    <div className="fill" />
                                    <span>{percentage.toFixed(2)}%</span>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DemographicsAnalytics;