// layouts/DashboardLayout.js
import React, { useEffect, useState } from 'react';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import { Check, Plus } from 'phosphor-react';
import CreateTeam from '../../Team/CreateTeam';
import Post from '../components/Post';

const DashboardLayout = ({ children, selectedPage, teamId, team, teams }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);
  const [postModalOpen, setPostModalOpen] = useState(false);


  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const getTeamInitials = (name = '') => {
    const teamName = name.trim() || 'Team';
    const words = teamName.split(' ');

    if (words.length >= 2) {
      return words[0][0].toUpperCase() + words[1][0].toUpperCase();
    }
    return teamName.slice(0, 2).toUpperCase();
  };

  console.log('teams', teams);

  return (
    <div className='under-container'>
      <div className="dashboard-container">
        <SideNav selectedPage={selectedPage} teamId={teamId} selectedTeam={team} toggleDropdown={toggleDropdown} dropdownActive={dropdownVisible} postModal={setPostModalOpen} />
        <div className="main-content">
          <Header team={team} />
          <div className="dynamic-content">
            {children}
          </div>
        </div>

        {team && (
          <>
            {dropdownVisible && (
              <div className="teamSel-dd-overlay" onClick={toggleDropdown}>
                <div className="teamSel-dd-content frosted" onClick={(e) => e.stopPropagation()}>
                  <div className="teamSel-dd-list">
                    {teams.map((team, index) => (
                      <Link key={index} to={`/app/${team.public_id}`} className="teamSel-dd-item">
                        {team.icon_url ? (
                          <img
                            src={`${team.icon_url}?v=${Date.now()}`}
                            alt='Team'
                            className='team-picture img-mini'
                          />
                        ) : (
                          <div
                            className='fallback-profile img-mini team-picture'
                            style={{ backgroundColor: team.fallback_colour }}
                          >
                            <p>{getTeamInitials(team.team_name)}</p>
                          </div>
                        )}
                        <p className='teamSel-dd-item-name'>
                          {team.team_name}
                        </p>
                        {team.public_id === teamId && (
                          <Check size={24} />
                        )}
                      </Link>
                    ))}
                    <div className='divider-dd' />
                    <div
                      className="teamSel-dd-add"
                      onClick={() => {
                        setCreateTeamModalOpen(true);
                        toggleDropdown();
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <Plus size={20} className='icon' />
                      <p className='teamSel-dd-item-name'>Create Team</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {createTeamModalOpen && (
          <CreateTeam closeModal={() => setCreateTeamModalOpen(false)} />
        )}

        {postModalOpen && (
          <Post closeModal={() => setPostModalOpen(false)} selectedTeam={team} />
        )}
      </div>
    </div>
  );
};

export default DashboardLayout;