import React from 'react';
import { useLocation, useNavigate, Routes, Route, Link, useParams, Navigate } from 'react-router-dom';
import { Bell, Buildings, Sliders, UserCircle, LinkSimple, UsersThree, Money } from 'phosphor-react';
import { useAuth } from '../../../context/AuthContext';
import { useDashboard } from '../../../hooks/useDashboard';

import './Settings.css';

import ChannelsBrands from './pages/Channels-Brands';
import UserManagement from './pages/Users';
import Account from './pages/Account';
import Preferences from './pages/Preferences';
import Teams from './pages/Team';
import Billing from './pages/Billing';

const Settings = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { teamId } = useParams();
    const { user } = useAuth();
    const { data, error, isLoading } = useDashboard(teamId);
    
    // Safe destructuring with defaults
    const { teams = [], selectedTeam = null } = data || {};
    
    // User details with fallbacks
    const color = user?.fallback_colour || '#cccccc';
    const profilePicture = user?.profile_picture;
    
    // Team details with fallbacks
    const teamName = selectedTeam?.team_name || 'Team Settings';
    const teamColor = selectedTeam?.fallback_colour || '#cccccc';
    const teamProfilePicture = selectedTeam?.icon_url;

    const getTeamInitials = (name = '') => {
        const teamName = name.trim() || 'Team';
        const words = teamName.split(' ');
        
        if (words.length >= 2) {
            return words[0][0].toUpperCase() + words[1][0].toUpperCase();
        }
        return teamName.slice(0, 2).toUpperCase();
    };

    const currentPage = location.pathname.split('/')[4] || 'account';

    return (
        <div className='settings-body'>
            {/* Sidebar */}
            <div className='settings-side frosted'>
                {/* User Profile Section */}
                <div className='settings-profile-section'>
                    <div className='settings-header-section'>
                        {profilePicture ? (
                            <img
                                src={profilePicture}
                                alt='Profile'
                                className='profile-picture img-mini'
                            />
                        ) : (
                            <div
                                className='fallback-profile img-mini profile-picture'
                                style={{ backgroundColor: color }}
                            >
                                {
                                    user && (
                                        <p>{user?.first_name?.[0]}{user?.last_name?.[0]}</p>
                                    )
                                }
                            </div>
                        )}
                        <p>{user?.first_name} {user?.last_name}</p>
                    </div>
                    
                    <div className='settings-body-section'>
                        <Link to={`/app/${teamId}/settings/account`} className='settings-body-item'>
                            <UserCircle className='icon' size={18} weight={currentPage === 'account' ? 'fill' : 'regular'} />
                            <p>Account</p>
                        </Link>
                        <Link to={`/app/${teamId}/settings/notifications`} className='settings-body-item'>
                            <Bell className='icon' size={18} weight={currentPage === 'notifications' ? 'fill' : 'regular'} />
                            <p>Notifications</p>
                        </Link>
                        <Link to={`/app/${teamId}/settings/preferences`} className='settings-body-item'>
                            <Sliders className='icon' size={18} weight={currentPage === 'preferences' ? 'fill' : 'regular'} />
                            <p>Preferences</p>
                        </Link>
                    </div>

                    {/* Team Section - Only show if team exists */}
                    {selectedTeam && (
                        <>
                            <div className='divider' />
                            <div className='settings-header-section'>
                                {teamProfilePicture ? (
                                    <img
                                        src={`${teamProfilePicture}?v=${Date.now()}`}
                                        alt='Team'
                                        className='team-picture img-mini'
                                    />
                                ) : (
                                    <div
                                        className='fallback-profile img-mini team-picture'
                                        style={{ backgroundColor: teamColor }}
                                    >
                                        <p>{getTeamInitials(teamName)}</p>
                                    </div>
                                )}
                                <p>{teamName}</p>
                            </div>
                            
                            <div className='settings-body-section'>
                                <Link to={`/app/${teamId}/settings/team`} className='settings-body-item'>
                                    <Buildings className='icon' size={18} weight={currentPage === 'team' ? 'fill' : 'regular'} />
                                    <p>Team</p>
                                </Link>
                                <Link to={`/app/${teamId}/settings/channels-brands`} className='settings-body-item'>
                                    <LinkSimple className='icon' size={18} weight={currentPage === 'channels-brands' ? 'fill' : 'regular'} />
                                    <p>Channels & Brands</p>
                                </Link>
                                <Link to={`/app/${teamId}/settings/users`} className='settings-body-item'>
                                    <UsersThree className='icon' size={18} weight={currentPage === 'users' ? 'fill' : 'regular'} />
                                    <p>Users</p>
                                </Link>
                                <Link to={`/app/${teamId}/settings/billing`} className='settings-body-item'>
                                    <Money className='icon' size={18} weight={currentPage === 'billing' ? 'fill' : 'regular'} />
                                    <p>Billing</p>
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Main Settings Area */}
            <div className='settings-main'>
                <Routes>
                    <Route path="account" element={<Account />} />
                    <Route path="notifications" element={<div>Notification Settings</div>} />
                    <Route path="preferences" element={<Preferences />} />
                    
                    {/* Team-related routes only available if team exists */}
                    {selectedTeam && (
                        <>
                            <Route path="team" element={<Teams />} />
                            <Route path="channels-brands" element={<ChannelsBrands />} />
                            <Route path="users" element={<UserManagement />} />
                            <Route path="billing" element={<Billing />} />
                        </>
                    )}
                    
                    <Route path="*" element={<Navigate to={`/app/${teamId}/settings/account`} replace />} />
                </Routes>
            </div>
        </div>
    );
};

export default Settings;