import React, { useState } from 'react';
import './SocialSetup.css';

import svgSocial from './social.svg';
import svgTeam from './team.svg';
import { Link } from 'react-router-dom';
import { X } from 'phosphor-react';

import CreateTeam from '../Team/CreateTeam'; // Import the CreateTeam component

const SocialSetup = ({ setup, team }) => {
    const repeated = Array(10).fill(null); // For followers skeleton
    const graphRows = Array(10).fill(null); // For graph-row-skeleton repetition

    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    console.log('Team:', team);


    const handleConnect = async (brandId) => {
        // Pass both publicId and brandId in state
        const state = JSON.stringify({
            publicId: team,
            brandId: brandId
        });

        window.location.href = process.env.REACT_APP_API_BASE_URL + `/auth/youtube?state=${encodeURIComponent(state)}`;
    };

    return (
        <div className='ss-page'>
            <div className="ss-container">
                <div className="small-container frosted widget">
                    <p className='widget-title'>
                        Impressions
                    </p>
                    <div className='top-bar-skeleton' />
                </div>
                <div className="small-container frosted widget">
                    <p className='widget-title'>
                        Views
                    </p>
                    <div className='top-bar-skeleton' />
                </div>
                <div className="small-container frosted widget">
                    <p className='widget-title'>
                        Likes
                    </p>
                    <div className='top-bar-skeleton' />
                </div>
                <div className="small-container frosted widget">
                    <p className='widget-title'>
                        Comments
                    </p>
                    <div className='top-bar-skeleton' />
                </div>

                {
                    setup === 'social' ? (
                        <div className="wide-container frosted widget">
                            <div className='widget-spanning'>
                                <img src={svgSocial} alt='social' className='scale-image-social' />
                                <div className='info-start'>
                                    <h2>One Last Step</h2>
                                    <p className='subtitle-socials-start'>Connect your socials to get started with Loopify</p>
                                    <button onClick={() => handleConnect(team.brands[0].brandId)} className='large-button'>youtube</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="wide-container frosted widget">
                            <div className='widget-spanning'>
                                <img src={svgTeam} alt='social' className='scale-image-social' />
                                <div className='info-start'>
                                    <h2>On your marks, Get Set...</h2>
                                    <button onClick={openModal} className='large-button'>Create a Team</button>
                                    <p className='subtitle-socials-start'>Or ask a team manager to invite you to one</p>

                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="normal-container frosted widget">
                    <p className='widget-title'>
                        Followers / Subscribers
                    </p>
                    {repeated.map((_, index) => (
                        <div key={index} className='followers-skeleton'>
                            <div className='circle-fol' />
                            <div className='text-fol' />
                        </div>
                    ))}
                </div>
                <div className="medium-container frosted widget">
                    <p className='widget-title'>
                        Views by Platform
                    </p>
                    <div className='data-skeleton'>
                        <div className='top-bar-skeleton' />
                        <div className='skeleton-data'>
                            <div className='circle-bar-skeleton' />
                            <div className='bars-skeletons'>
                                <div className='bar-skeleton' />
                                <div className='bar-skeleton' />
                                <div className='bar-skeleton' />
                                <div className='bar-skeleton' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="medium-container frosted widget">
                    <p className='widget-title'>
                        Top Content
                    </p>
                    <div className='data-skeleton'>
                        <div className='graph-skeleton'>
                            <div className='graph-skeleton-header'>
                                <p className='bar-skeleton-text'>Title</p>
                                <div className='fill' />
                                <p className='bar-skeleton-text'>Title</p>
                                <p className='bar-skeleton-text'>Title</p>
                                <p className='bar-skeleton-text'>Title</p>
                            </div>
                        </div>
                        <div className='graph-skeleton-data'>
                            {graphRows.map((_, index) => (
                                <div key={index} className='graph-row-skeleton'>
                                    <div className='circle-fol-graph' />
                                    <div className='bar-skeleton-row large' />
                                    <div className='bar-skeleton-row' />
                                    <div className='bar-skeleton-row' />
                                    <div className='bar-skeleton-row' />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for Create Team */}
            {isModalOpen && (
                <CreateTeam closeModal={closeModal} />
            )}
        </div>
    );
};

export default SocialSetup;