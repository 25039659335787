import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import './Billing.css';
import { CaretRight, Pencil } from 'phosphor-react';
import { format } from 'date-fns';
import api from '../../../../api';
import { useParams } from 'react-router-dom';

const Billing = () => {
    const { user } = useAuth();
    const [subscription, setSubscription] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { teamId } = useParams();

    useEffect(() => {
        const fetchBillingData = async () => {
            try {
                const { data } = await api.get(`/${teamId}/team/billing`);
                setSubscription(data.subscription);
                setPaymentMethod(data.paymentMethod);
                setInvoices(data.invoices);
            } catch (err) {
                setError('Failed to load billing information');
            } finally {
                setLoading(false);
            }
        };

        fetchBillingData();
    }, [teamId]);

    if (loading) return <div className="loading-spinner">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className='settings-body-sub'>
            <div className='title-settings'>
                <h2>Billing</h2>
            </div>
            <div className='dynamic-main-settings frosted'>
                <div className='inner-container'>
                    <div className='overview-section-billing'>
                        <div className='billing-header'>
                            <h3>Overview</h3>
                            <div className='fill' />
                            <button className='button-sub'>Cancel Membership</button>
                            <button className='button-secondary'>Upgrade Plan</button>
                        </div>
                        <div className='billing-info'>
                        <div className='divider-dd' />
                            <div className='billing-item-details'>
                                <div className='billing-info-item'>
                                    <h4>Available Users</h4>
                                    <p>The remaining users on your current loopify plan</p>
                                </div>
                                <div className='fill' />
                                <p>
                                    X
                                </p>
                            </div>
                            <div className='divider-dd' />
                            <div className='billing-item-details'>
                                <div className='billing-info-item'>
                                    <h4>Available Channels</h4>
                                    <p>Remaining channels on your current loopify plan</p>
                                </div>
                                <div className='fill' />
                                <p>
                                    X
                                </p>
                            </div>
                            <div className='divider-dd' />
                            <div className='billing-item-details'>
                                <div className='billing-info-item'>
                                    <h4>Next Billing Date</h4>
                                    <p>Your next automatic billing date</p>
                                </div>
                                <div className='fill' />
                                <p>
                                    {subscription?.current_period_end 
                                        ? format(new Date(subscription.current_period_end * 1000), 'MM/dd/yyyy')
                                        : 'N/A'}
                                </p>
                            </div>
                            <div className='divider-dd' />
                            <div className='billing-item-details'>
                                <div className='billing-info-item'>
                                    <h4>Price</h4>
                                    <p>{subscription?.plan?.product?.name || 'TIER TYPE'}</p>
                                </div>
                                <div className='fill' />
                                <p>
                                    ${(subscription?.plan?.amount / 100).toFixed(2)} / 
                                    {subscription?.plan?.interval}
                                </p>
                            </div>
                        </div>
                        <br />
                        <h3>Payment Details</h3>
                        <div className='billing-item-details'>
                            <div className='billing-info-item'>
                                <h4>Payment Method</h4>
                            </div>
                            <div className='fill' />
                            <p>
                                {paymentMethod?.card 
                                    ? `${paymentMethod.card.brand.toUpperCase()} **** ${paymentMethod.card.last4}`
                                    : 'No payment method on file'}
                            </p>
                        </div>
                        <br />
                        <h3>Payment History</h3>
                        <div className='table-container frosted'>
                            <table className='billing-table'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices.map(invoice => (
                                        <tr key={invoice.id}>
                                            <td>
                                                {format(new Date(invoice.created * 1000), 'MM/dd/yyyy')}
                                            </td>
                                            <td>
                                                ${(invoice.amount_paid / 100).toFixed(2)}
                                            </td>
                                            <td>
                                                <span className={`status-${invoice.status}`}>
                                                    {invoice.status[0].toUpperCase() + invoice.status.slice(1)}
                                                </span>
                                            </td>
                                            <td className='mini-table-pod'>
                                                <button className=''>
                                                    <CaretRight size={24} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Billing;