import React, { useState, useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query'; // Changed import
import { useAuth } from '../../../../context/AuthContext';
import api from '../../../../api';
import './Team.css';
import { Pencil } from 'phosphor-react';
import { useDashboard } from '../../../../hooks/useDashboard';
import { useParams } from 'react-router-dom';

const Team = () => {
    const { teamId } = useParams();
    const queryClient = useQueryClient();
    const { user } = useAuth();
    const [isHovering, setIsHovering] = useState(false);
    const fileInputRef = useRef(null);
    const [imageVersion, setImageVersion] = useState(Date.now());

    const { data, error, isLoading } = useDashboard(teamId);
    const { teams = [], selectedTeam } = data || {}; // Add default for teams
    const [teamName, setTeamName] = useState(selectedTeam?.team_name || '');
    const [profilePicture, setProfilePicture] = useState(selectedTeam?.icon_url || '');

    // Track timeout references
    const timeoutRef = useRef({
        teamName: null
    });

    const color = selectedTeam?.fallback_colour || '#cccccc';

    const getTeamInitials = (teamName) => {
        if (!teamName) return '';
        const words = teamName.split(' ');
        if (words.length === 1) {
            return teamName.slice(0, 2).toUpperCase();
        }
        return words.slice(0, 2).map(word => word[0]?.toUpperCase() || '').join('');
    };

    // Sync local state with selected team updates
    useEffect(() => {
        setTeamName(selectedTeam.team_name);

        if (selectedTeam.icon_url) {
            setProfilePicture(`${selectedTeam.icon_url}?v=${imageVersion}`);
        }

    }, [selectedTeam, imageVersion]);

    // Cleanup timeouts on unmount
    useEffect(() => {
        return () => {
            Object.values(timeoutRef.current).forEach(timeout => {
                if (timeout) clearTimeout(timeout);
            });
        };
    }, []);

    const debouncedUpdate = async (field, value) => {
        if (timeoutRef.current[field]) {
            clearTimeout(timeoutRef.current[field]);
        }

        timeoutRef.current[field] = setTimeout(async () => {
            try {
                // Use PATCH instead of POST
                const response = await api.post(`/${teamId}/rename`, {
                    [field]: value
                });

                if (response.data?.team) {

                    selectedTeam.icon_url = response.data.team.icon_url;
                    // update the cache
                    queryClient.setQueryData(['dashboard', teamId], (oldData) => {
                        const baseData = oldData || { teams: [], selectedTeam: null };
                        return {
                            ...baseData,
                            teams: baseData.teams.map(team =>
                                team.public_id === teamId
                                    ? { ...team, team_name: value }
                                    : team
                            ),
                            selectedTeam: {
                                ...(baseData.selectedTeam || {}),
                                team_name: value
                            }
                        };
                    });
                }
            } catch (error) {
                console.error('Update failed:', error);
                // Revert to original value on error
                setTeamName(selectedTeam?.team_name || '');
            }
        }, 3000);
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await api.post(`/${teamId}/team-picture`, formData);
            event.target.value = null;

            if (response.data?.team) {
                // Add cache busting to image URL
                //setImageVersion(Date.now());
                setProfilePicture(`${response.data.team.icon_url}?v=${Date.now()}`);

                console.log('Profile Picture:', response.data.team.icon_url);

                //update cache
                queryClient.setQueryData(['dashboard', teamId], (oldData) => {
                    const baseData = oldData || { teams: [], selectedTeam: null };
                    return {
                        ...baseData,
                        teams: baseData.teams.map(team =>
                            team.public_id === teamId
                                ? { ...team, icon_url: response.data.team.icon_url }
                                : team
                        ),
                        selectedTeam: {
                            ...(baseData.selectedTeam || {}),
                            icon_url: response.data.team.icon_url
                        }
                    };
                });

                event.target.value = null;
            }
        } catch (error) {
            console.error('Error uploading profile picture:', error);
            alert('Failed to upload profile picture');
            event.target.value = null;
        }
    };

    return (
        <div className='settings-body-sub'>
            <div className='title-settings'>
                <h2>My Team</h2>
            </div>
            <div className='dynamic-main-settings frosted'>
                <div className='inner-container'>
                    {/* Profile Picture Section */}
                    <div className='account-top-section'>
                        <div
                            className='profile-picture-edit'
                            onMouseEnter={() => setIsHovering(true)}
                            onMouseLeave={() => setIsHovering(false)}
                        >
                            <div
                                className='team-overlay'
                                style={{ opacity: isHovering ? 1 : 0 }}
                                onClick={() => fileInputRef.current.click()}
                            >
                                <Pencil size={24} color='white' />
                            </div>

                            {profilePicture ? (
                                <img
                                    src={profilePicture}
                                    alt='Profile'
                                    className='team-picture'
                                    style={{ opacity: isHovering ? 0.7 : 1 }}
                                />
                            ) : (
                                <div
                                    className='fallback-profile team-picture'
                                    style={{
                                        backgroundColor: color,
                                        opacity: isHovering ? 0.7 : 1
                                    }}
                                >
                                    <p>{getTeamInitials(teamName)}</p>
                                </div>
                            )}

                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                                accept="image/*"
                            />
                        </div>

                        <h2 className='account-name-title'>
                            {teamName}
                        </h2>
                    </div>

                    <div className='spacing-20' />

                    <h3>Team Name</h3>
                    <div className='details'>
                        <div className='details-item'>
                            <p className='label'>Team Name</p>
                            <div className='fill' />
                            <input
                                type='text'
                                value={teamName}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setTeamName(value);
                                    debouncedUpdate('team_name', value);
                                }}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Team;