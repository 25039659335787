// pages/Dashboard/Dashboard.js
import React, { useEffect } from 'react';
import { useNavigate, useParams, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useDashboard } from '../../hooks/useDashboard';
import { useAuth } from '../../context/AuthContext';
import DashboardLayout from './layouts/DashboardLayout';

import Overview from './pages/Overview';
import Schedule from './pages/Schedule';
import Analytics from './pages/Analytics';

import SocialSetup from '../Socials/SocialSetup';
import Settings from './management/Settings';
import './Dashboard.css';
import Logout from '../Auth/Logout';

const Dashboard = () => {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, error, isLoading } = useDashboard(teamId);
  const { isAuthenticated, needsMetadata } = useAuth();

  const currentPage = location.pathname.split('/')[3] || 'overview';

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate('/login');
      } else if (needsMetadata) {
        navigate('/complete-account');
      }
    }
  }, [isAuthenticated, needsMetadata, navigate]);

    // Set document title based on selected team
    useEffect(() => {
      if (data?.selectedTeam?.team_name) {
        document.title = `${data.selectedTeam.team_name} - loopify`;
      } else if (!isLoading && !data?.teams?.length) {
        document.title = 'Create Team - loopify';
      } else {
        document.title = 'loopify';
      }
    }, [data, isLoading]);  


  //if error from useDashboard hook
  if (error && !isAuthenticated) {
    return <Navigate to='/logout' replace />;
  }

  if (isLoading) return <p>loading...</p>;

  const { teams = [], selectedTeam } = data || {};
  const hasTeams = teams?.length > 0;


  // Handle initial team selection
  if (hasTeams && !teamId) {
    const firstTeamId = teams[0].public_id || 'new';
    return <Navigate to={`/app/${firstTeamId}`} replace />;
  }

  // Handle team creation flow
  if (!hasTeams) {
    return (
      <DashboardLayout selectedPage={currentPage} teamId={'new'} team={null} teams={null}>
        <Routes>
          <Route path="/create-team" element={<SocialSetup setup={'team'} teamId={null} />} />
          <Route path="/settings/*" element={<Settings />} />
          <Route path='/logout' element={<Logout />} />

          <Route path="*" element={<Navigate to="/app/new/create-team" replace />} />
        </Routes>
      </DashboardLayout>
    );
  }

  // Validate team ID match
  if (teamId !== selectedTeam.public_id) {
    return <Navigate to={`/app/${selectedTeam.public_id}`} replace />;
  }

  // Check if social setup is needed
  const shouldSetup = selectedTeam.brands.every((brand) => brand.socials.length === 0);

  return (
    <DashboardLayout selectedPage={currentPage} teamId={selectedTeam.public_id} team={selectedTeam} teams={teams}>
      <Routes>
        {shouldSetup ? (
          //needs to setup socials
          <>
            <Route path="/setup" element={<SocialSetup setup={'social'} team={selectedTeam} />} />
            <Route path="/create-team" element={<SocialSetup setup={'team'} teamId={null} />} />
            <Route path="/settings/*" element={<Settings />} />
            <Route path='/logout' element={<Logout />} />
            <Route path="*" element={<Navigate to={`/app/${teamId}/setup`} replace />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Overview team={selectedTeam} />} />

            <Route path="/schedule" element={<Schedule team={selectedTeam} />} />
            <Route path="/analytics" element={<Analytics team={selectedTeam} />} />
            <Route path="/settings/*" element={<Settings />} />

            <Route path="/create-team" element={<SocialSetup setup={'team'} teamId={null} />} />

            <Route path='/logout' element={<Logout />} />
            <Route path="*" element={<Navigate to={`/app/${teamId}/`} replace />} />
          </>
        )}
      </Routes>
    </DashboardLayout>
  );
};

export default Dashboard;