import React, { useEffect, useMemo, useState } from 'react';
import { capitalize, pretty } from '../../../utils';
import { format, isSameMonth, isSameDay, startOfWeek, endOfWeek, eachDayOfInterval, addDays, subDays, getDaysInMonth, startOfMonth, endOfMonth } from 'date-fns';
import { CalendarBlank } from 'phosphor-react';

import YoutubeLogo from '../../../assets/logos/youtube.png';
import TiktokLogo from '../../../assets/logos/tiktok.png';

const ScheduleView = ({ posts, view, currentDate }) => {
    const isSameWeek = (date1, date2) => {
        const weekStart = startOfWeek(date2);
        const weekEnd = endOfWeek(date2);
        return date1 >= weekStart && date1 <= weekEnd;
    };

    const isSameMonth = (date1, date2) => {
        return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
    };

    const validPosts = useMemo(() => {
        const now = new Date();
        return posts.filter(post => {
            const postDate = new Date(post.post_time);
            switch (view) {
                case 'week':
                    return isSameWeek(postDate, currentDate);
                case 'month':
                case 'list':
                    return isSameMonth(postDate, currentDate);
                default:
                    return true;
            }
        }).sort((a, b) => new Date(a.post_time) - new Date(b.post_time));
    }, [posts, view, currentDate]);

    const sortedPosts = useMemo(() =>
        [...validPosts].sort((a, b) => new Date(a.post_time) - new Date(b.post_time)),
        [validPosts]
    );

    const formatDate = (date) => format(date, 'MMM d');
    const isToday = (date) => isSameDay(date, new Date());

    const getPostStatus = (post) => {
        const privacyStatus = post.status?.privacyStatus; // Ensure status exists
        const publishTime = post.post_time ? new Date(post.post_time) : null;
        const now = Date.now();

        //if future post
        if (publishTime && publishTime > now) {
            return 'scheduled';
        }
        if (publishTime == null) {
            return 'hidden';
        }
        return 'posted';
    };

    const getPlatforms = (post) => {
        const platforms = [];
        if (post.youtube_id) platforms.push(YoutubeLogo);
        if (post.tiktok_id) platforms.push(TiktokLogo);
        return platforms;
    };

    // Week View Implementation
    const WeekView = () => {
        const weekStart = startOfWeek(currentDate);
        const weekDays = eachDayOfInterval({
            start: weekStart,
            end: endOfWeek(currentDate)
        });

        return (
            <div className="week-view">
                {weekDays.map((day, index) => {
                    const dayPosts = sortedPosts.filter(post =>
                        isSameDay(new Date(post.post_time), day)
                    );

                    return (
                        <div key={index} className="day-column">
                            <div className={`day-header ${isToday(day) ? 'today' : ''}`}>
                                {format(day, 'EEE') + ' ' + format(day, 'd')}
                            </div>
                            <div className="day-posts">
                                {dayPosts.map((post, idx) => (
                                    <div key={idx} className={`post-item ${getPostStatus(post)}`}>
                                        <div className="post-content">
                                            <p className="post-title">{pretty(post.title)}</p>
                                            <p className="post-time">{format(new Date(post.post_time), 'p')}</p>
                                            <div className="post-footer">
                                                <div className='post-platforms'>
                                                    {
                                                        getPlatforms(post).map((platform, idx) => (
                                                            <img key={idx} src={platform} className='platform-logo img-very-mini' />
                                                        ))
                                                    }
                                                </div>
                                                <img src={post.brand_logo} className='team-picture img-very-mini' />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    // Month View Implementation
    const MonthView = () => {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(currentDate);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        const totalDays = 35; // 6 weeks

        const daysGrid = Array.from({ length: totalDays }, (_, i) => {
            const day = addDays(startDate, i);
            return {
                date: day,
                isCurrentMonth: isSameMonth(day, currentDate),
                posts: sortedPosts.filter(post =>
                    isSameDay(new Date(post.post_time), day)
                )
            };
        });

        return (
            <div className="month-grid">
                {daysGrid.map((day, index) => (
                    <div
                        key={index}
                        className={`day-cell ${day.isCurrentMonth ? 'current-month' : 'other-month'} ${isToday(day.date) ? 'today' : ''}`}
                    >
                        <div className="cell-header">
                            {format(day.date, 'd')}
                        </div>
                        <div className="cell-posts">
                            {day.posts.map((post, idx) => (
                                <div key={idx} className={`post-bubble ${getPostStatus(post)}`}>
                                    <div className='post-month-content'>
                                        <p className="post-title">
                                            {post.title}
                                        </p>
                                        <div className='post-platforms'>
                                            {
                                                getPlatforms(post).map((platform, idx) => (
                                                    <img key={idx} src={platform} className='platform-logo img-ultra-mini' />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    // List View Implementation
    const ListView = () => (
        <div className="list-view">
            <table className="list-table">
                <thead>
                    <tr>
                        <th>Channel</th>
                        <th>Title</th>
                        <th>Platforms</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedPosts.map((post, index) => {
                        const postDate = new Date(post.post_time);
                        const status = getPostStatus(post);
                        return (
                            <tr key={index} className={`list-item ${status}`}>
                                <td className="channel">
                                    {
                                        //loop through list
                                        post.channel_logos.map((logo, idx) => (
                                            <img key={idx} src={logo} className='platform-logo img-mini' />
                                        ))
                                    }
                                </td>
                                <td className="title">
                                    <div className="title-content">
                                        {pretty(post.title)}
                                    </div>
                                </td>
                                <td className="platform">
                                    <div className='post-platforms'>
                                        {getPlatforms(post).map((platform, idx) => (
                                            <img key={idx} src={platform} className='platform-logo img-very-mini' />
                                        ))}
                                    </div>
                                </td>
                                <td className="list-date-status">
                                    <div className="list-date-status-content">
                                        <CalendarBlank size={16} />
                                        {format(postDate, 'MMM d, p')}
                                    </div>
                                </td>
                                <td className={`list-status`}>
                                    <div className={`list-status-content ${status}`}>
                                        <span className={`status-circle ${status}`}></span>
                                        {capitalize(status)}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="schedule-view">
            {view === 'week' && <WeekView />}
            {view === 'month' && <MonthView />}
            {view === 'list' && <ListView />}
        </div>
    );
};

export default ScheduleView;