import { useQuery } from '@tanstack/react-query';
import api from '../api';

export const useUser = () => {
  return useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      try {

        const response = await api.get('/auth/verify');
        if (response.data.error === 'UMNSU') {
          return { 
            ...response.data.data,
            needsMetadata: true 
          };
        }
        return response.data.data;

      } catch (error) {

        if (error.response?.status === 401) {
          return null;
        }

        throw error;
      }
    },
    retry: false,
    staleTime: 1000 * 60 * 5,
  });
};