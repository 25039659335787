// pages/Auth/InviteHandler.jsx
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import api from '../../api';

const InviteHandler = () => {
    const { token } = useParams();
    const { user, isAuthenticated, isLoading, needsMetadata } = useAuth();
    const navigate = useNavigate();

    console.log('InviteHandler:', token, isAuthenticated, isLoading, needsMetadata);

    useEffect(() => {
        const handleInvite = async () => {
            try {
                if (!token) {
                    navigate('/');
                    return;
                }

                if (isAuthenticated) {
                    if (needsMetadata) {
                        localStorage.setItem('inviteToken', token);
                        navigate('/complete-account');
                    } else {
                        const response = await api.post(`/invite/${token}`);
                        navigate(`/app/${response.data.teamId}`);
                        localStorage.removeItem('inviteToken');
                    }
                } else {
                    localStorage.setItem('inviteToken', token);
                    navigate('/login', { state: { fromInvite: true } });
                }
            } catch (error) {
                console.error('Error handling invitation:', error);
                navigate('/', { state: { error: error.message } });
            }
        };

        if (!isLoading) handleInvite();
    }, [token, isAuthenticated, needsMetadata, navigate, isLoading]);

    return <div>Processing invitation...</div>;
};

export default InviteHandler;