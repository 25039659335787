import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import { useQueryClient } from '@tanstack/react-query';
import api from '../../../../api';
import './Account.css';
import { Pencil } from 'phosphor-react';

const Account = () => {
    const { user, updateUser } = useAuth();
    const [isHovering, setIsHovering] = useState(false);
    const fileInputRef = useRef(null);
    const queryClient = useQueryClient();
    const [imageVersion, setImageVersion] = useState(Date.now());

    // Local state for editable fields
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);

    // Track timeout references
    const timeoutRef = useRef({
        firstName: null,
        lastName: null,
        email: null
    });

    const color = user.fallback_colour;
    const [profilePicture, setProfilePicture] = useState(user?.profile_picture || '');

    console.log('profilePicture:', profilePicture);

    // Sync local state with user context updates
    useEffect(() => {
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setEmail(user.email);

        if (user.profile_picture) {
            setProfilePicture(`${user.profile_picture}?v=${imageVersion}`);
        }
    }, [user, imageVersion]);

    // Cleanup timeouts on unmount
    useEffect(() => {
        return () => {
            Object.values(timeoutRef.current).forEach(timeout => {
                if (timeout) clearTimeout(timeout);
            });
        };
    }, []);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const formData = new FormData();
            formData.append('profile_picture', file);

            // Call your API endpoint
            const response = await api.post('/upload-profile-picture', formData);

            if (response.status === 200) {
                const url = response.data.url;

                user.profile_picture = url;

                console.log('Profile Picture URL:', url);

                //update cache
                queryClient.setQueryData('user', (prev) => ({
                    ...prev,
                    profile_picture: url
                }));



                //force refresh image
                setImageVersion(Date.now());
            }

            event.target.value = null;
        } catch (error) {
            console.error('Error uploading profile picture:', error);
            alert('Failed to upload profile picture');

            event.target.value = null;
        }
    };

    const debouncedUpdate = async (field, value) => {
        // Clear existing timeout for this field
        if (timeoutRef.current[field]) {
            clearTimeout(timeoutRef.current[field]);
        }

        // Set new timeout
        timeoutRef.current[field] = setTimeout(async () => {
            try {
                const response = await api.post('/users/update', {
                    [field]: value
                });

                if (response.data?.user) {
                    //update cache
                    queryClient.setQueryData('user', (prev) => ({
                        ...prev,
                        [field]: value
                    }));
                }
            } catch (error) {
                console.error(`Error updating ${field}:`, error);
                // Revert to original value
                switch (field) {
                    case 'first_name':
                        setFirstName(user.first_name);
                        break;
                    case 'last_name':
                        setLastName(user.last_name);
                        break;
                    case 'email':
                        setEmail(user.email);
                        break;
                    default:
                        break;
                }
            }
        }, 3000); // 3 second delay
    };

    return (
        <div className='settings-body-sub'>
            <div className='title-settings'>
                <h2>My Account</h2>
            </div>
            <div className='dynamic-main-settings frosted'>
                <div className='inner-container'>
                    {/* Profile Picture Section */}
                    <div className='account-top-section'>
                        <div
                            className='profile-picture-edit'
                            onMouseEnter={() => setIsHovering(true)}
                            onMouseLeave={() => setIsHovering(false)}
                        >
                            <div
                                className='profile-overlay'
                                style={{ opacity: isHovering ? 1 : 0 }}
                                onClick={() => fileInputRef.current.click()}
                            >
                                <Pencil size={24} color='white' />
                            </div>

                            {profilePicture ? (
                                <img
                                    src={profilePicture}
                                    alt='Profile'
                                    className='profile-picture'
                                    style={{ opacity: isHovering ? 0.7 : 1 }}
                                />
                            ) : (
                                <div
                                    className='fallback-profile profile-picture'
                                    style={{
                                        backgroundColor: color,
                                        opacity: isHovering ? 0.7 : 1
                                    }}
                                >
                                    {
                                        (firstName && lastName) && (
                                            <p>{firstName.charAt(0)}{lastName.charAt(0)}</p>
                                        )
                                    }
                                </div>
                            )}

                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                                accept="image/*"
                            />
                        </div>

                        <h2 className='account-name-title'>
                            {firstName} {lastName}
                        </h2>
                    </div>

                    <div className='spacing-20' />

                    {/* Account Details Section */}
                    <h3>Account Details</h3>
                    <div className='details'>
                        <div className='details-item'>
                            <p className='label'>First Name</p>
                            <div className='fill' />
                            <input
                                type='text'
                                value={firstName}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setFirstName(value);
                                    debouncedUpdate('first_name', value);
                                }}
                            />
                        </div>
                        <div className='details-item'>
                            <p>Last Name</p>
                            <div className='fill' />
                            <input
                                type='text'
                                value={lastName}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setLastName(value);
                                    debouncedUpdate('last_name', value);
                                }}
                            />
                        </div>
                    </div>

                    {
                        user.method !== 'google' && (
                            <>
                                <div className='spacing-20' />

                                {/* Email Section */}
                                <h3>Email</h3>
                                <div className='details'>
                                    <div className='details-item'>
                                        <p className='label'>Email</p>
                                        <div className='fill' />
                                        <input
                                            type='email'
                                            value={email}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setEmail(value);
                                                debouncedUpdate('email', value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    }



                    <div className='spacing-20' />

                    {/* Security and Data Section */}
                    <h3>Security and Data</h3>
                    <div className='details'>
                        {
                            user.method !== 'google' && (
                                <div className='details-item'>
                                    <p className='label'>Password</p>
                                    <div className='fill' />
                                    <button className='edit-button'>Change Password</button>
                                </div>
                            )
                        }
                        <div className='details-item'>
                            <p className='label'>Delete your account</p>
                            <div className='fill' />
                            <button className='delete-button'>Delete Account</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;