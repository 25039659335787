import React, { useEffect, useRef, useState } from 'react';
import api from '../../../api';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Pie, PieChart, Cell } from 'recharts';
import { formatNumber } from '../../../utils';
import { CaretDown } from 'phosphor-react';

import YoutubeLogo from '../../../assets/logos/youtube.png';
import TiktokLogo from '../../../assets/logos/tiktok.png';

const COLORS = ['#E99494', '#CE94E9', '#94E9B8', '#92BFFF'];

const Overview = ({ team }) => {
    const [analyticsData, setAnalyticsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [graphView, setGraphView] = useState('followers');

    // All brands and platforms available for filtering
    const brands = team?.brands || [];
    const platforms = [...new Set(brands.flatMap(brand =>
        brand.socials?.map(social => social.type) || []
    ))];

    const [availableBrands] = useState(brands);
    const [availablePlatforms] = useState(platforms);

    // Selected filters
    // Note: the team brands have property "brand_id", but daily channel objects use "brandId"
    const [selectedBrands, setSelectedBrands] = useState(brands.map(brand => brand.brand_id));
    const [selectedPlatforms, setSelectedPlatforms] = useState(platforms);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('30d');
    const [openDropdown, setOpenDropdown] = useState(null);
    const [platformPieData, setPlatformPieData] = useState([]);

    const containerRef = useRef(null);

    // Utility: Calculate start date from selected time period
    const getStartDate = (period) => {
        const now = new Date();
        switch (period) {
            case '7d': return new Date(now.setDate(now.getDate() - 7));
            case '30d': return new Date(now.setDate(now.getDate() - 30));
            case '90d': return new Date(now.setDate(now.getDate() - 90));
            case 'ytd': return new Date(now.getFullYear(), 0, 1);
            default: return new Date(now.setDate(now.getDate() - 30));
        }
    };

    // Fetch all analytics data without passing brand/platform filters
    useEffect(() => {
        const fetchAnalytics = async () => {
            try {
                setLoading(true);
                setError(null);
                const startDate = getStartDate(selectedTimePeriod);
                const { data } = await api.get('/analytics/summary', {
                    params: {
                        publicId: team.public_id,
                        startDate: startDate.toISOString().split('T')[0],
                        endDate: new Date().toISOString().split('T')[0],
                    }
                });
                data?.data ? setAnalyticsData(data.data) : setError('Invalid data format');
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch analytics');
                console.error('Fetch error:', err);
            } finally {
                setLoading(false);
            }
        };

        if (team?.public_id) fetchAnalytics();
    }, [team, selectedTimePeriod]);

    // Update platform pie chart data based on locally filtered channels
    useEffect(() => {
        if (analyticsData?.channels) {
            // Note: the full channels array from analyticsData uses "brand_id"
            const filteredChannels = analyticsData.channels.filter(channel =>
                selectedPlatforms.includes(channel.type) &&
                selectedBrands.includes(channel.brand_id)
            );

            console.log('Filtered Channels:', filteredChannels);
            console.log('Selected Platforms:', selectedPlatforms);
            console.log('Selected Brands:', selectedBrands);

            const platformViews = {};
            filteredChannels.forEach(channel => {
                const type = channel.type.charAt(0).toUpperCase() + channel.type.slice(1);
                const views = parseInt(channel.viewCount, 10);
                platformViews[type] = (platformViews[type] || 0) + views;
            });

            const totalViews = Object.values(platformViews).reduce((acc, views) => acc + views, 0);
            const platformPieArray = Object.keys(platformViews)
                .filter(key => platformViews[key] > 0)
                .map(key => ({
                    name: key,
                    value: platformViews[key],
                    percentage: ((platformViews[key] / totalViews) * 100).toFixed(2)
                }));

            setPlatformPieData(platformPieArray);
            console.log('Platform Views:', platformPieArray);
        }
    }, [analyticsData, selectedPlatforms, selectedBrands]);

    // Close dropdown on outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.filter-dropdown')) {
                setOpenDropdown(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    if (loading) {
        return <div className="loading-spinner">Loading analytics...</div>;
    }
    if (error) {
        return <div className="error-message">{error}</div>;
    }
    if (!analyticsData) {
        return <div>No analytics data available for this selection</div>;
    }

    console.log('Analytics Data:', analyticsData);
    console.log('Selected Brands:', selectedBrands);
    console.log('Selected Platforms:', selectedPlatforms);

    // Apply local filtering to daily data:
    // For each day, only include channels matching the selected platforms AND whose brandId is in selectedBrands.
    // Note: Daily channel objects use "brandId" (capital "I").
    const filteredDays = analyticsData.data.map(day => ({
        ...day,
        channels: day.channels.filter(channel =>
            selectedPlatforms.includes(channel.type) &&
            selectedBrands.includes(channel.brandId)
        )
    }));

    // Calculate chart data from the filteredDays
    const chartData = filteredDays.map(day => ({
        date: day.date,
        views: day.channels.reduce((acc, channel) => acc + (channel.views || 0), 0),
        likes: day.channels.reduce((acc, channel) => acc + (channel.likes || 0), 0),
        comments: day.channels.reduce((acc, channel) => acc + (channel.comments || 0), 0),
        followers: day.channels.reduce((acc, channel) => acc + (channel.subscribersGained || channel.followers || 0), 0),
    }));

    // For the top content, filter only by platform since brand info isn’t available
    const filteredTopContent = analyticsData.content.filter(content =>
        selectedPlatforms.includes(content.type) &&
        selectedBrands.includes(content.brand_id)
    );

    const handleGraphChange = (metric) => setGraphView(metric);

    const formatXAxis = (tickItem) => {
        const date = new Date(tickItem);
        const options = { month: 'short', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    const formatYAxis = (tickItem) => tickItem >= 1000 ? `${tickItem / 1000}k` : tickItem;
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const date = new Date(label);
            const formattedDate = date.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });
            return (
                <div className="custom-tooltip frosted">
                    <p className="label">{formattedDate}</p>
                    <p className="intro">{`${capitalizeFirstLetter(graphView)}: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    const handleBrandChange = (brandId) => {
        setSelectedBrands(prev => prev.includes(brandId)
            ? prev.filter(id => id !== brandId)
            : [...prev, brandId]
        );
    };

    const handlePlatformChange = (platform) => {
        setSelectedPlatforms(prev => prev.includes(platform)
            ? prev.filter(p => p !== platform)
            : [...prev, platform]
        );
    };

    const DropdownMenu = ({ title, children }) => (
        <div className="filter-dropdown">
            <button onClick={() => setOpenDropdown(openDropdown === title ? null : title)}>
                {title !== 'Time' ? (
                    <>
                        {title + ':'}
                        <span className="selected-count">
                            {title === 'Platforms'
                                ? selectedPlatforms.length === availablePlatforms.length ? 'All' : selectedPlatforms.length
                                : selectedBrands.length === availableBrands.length ? 'All' : selectedBrands.length
                            }
                        </span>
                    </>
                ) : (
                    <>
                        {selectedTimePeriod === '7d' ? 'Last 7 Days' : ''}
                        {selectedTimePeriod === '30d' ? 'Last 30 Days' : ''}
                        {selectedTimePeriod === '90d' ? 'Last 90 Days' : ''}
                        {selectedTimePeriod === 'ytd' ? 'Year to Date' : ''}
                        {selectedTimePeriod === 'all' ? 'All Time' : ''}
                    </>
                )}
                <CaretDown size={18} />
            </button>
            {openDropdown === title && (
                <div className="dropdown-content" onClick={(e) => e.stopPropagation()}>
                    {children}
                </div>
            )}
        </div>
    );

    // Remove trailing days with null followers (if viewing followers)
    if (graphView === 'followers') {
        while (chartData.length && chartData[chartData.length - 1].followers == null) {
            chartData.pop();
        }
    }

    // Aggregate follower counts by social type (from the full channels array, filtering by platform and brand)
    const aggregatedFollowers = analyticsData.channels
        .filter(channel =>
            selectedPlatforms.includes(channel.type) &&
            selectedBrands.includes(channel.brand_id)
        )
        .reduce((acc, channel) => {
            const type = channel.type;
            const followers = channel.subscriberCount || channel.followers;
            const intFoll = parseInt(followers, 10);
            acc[type] = (acc[type] || 0) + intFoll;
            return acc;
        }, {});

    console.log('Aggregated Followers:', aggregatedFollowers);

    const getLogo = (type) => {
        switch (type.toLowerCase()) {
            case 'youtube': return YoutubeLogo;
            case 'tiktok': return TiktokLogo;
            default: return null;
        }
    };

    // Calculate totals for the widgets using filteredDays
    const totalSubscribersGained = filteredDays.reduce((acc, day) =>
        acc + day.channels.reduce((channelAcc, channel) => channelAcc + (channel.subscribersGained || 0), 0)
        , 0);
    const totalViews = filteredDays.reduce((acc, day) =>
        acc + day.channels.reduce((channelAcc, channel) => channelAcc + (channel.views || 0), 0)
        , 0);
    const totalLikes = filteredDays.reduce((acc, day) =>
        acc + day.channels.reduce((channelAcc, channel) => channelAcc + (channel.likes || 0), 0)
        , 0);
    const totalComments = filteredDays.reduce((acc, day) =>
        acc + day.channels.reduce((channelAcc, channel) => channelAcc + (channel.comments || 0), 0)
        , 0);

    return (
        <div className="ss-page" ref={containerRef}>
            <div className="ss-header">
                <DropdownMenu title="Platforms">
                    {availablePlatforms.map(platform => (
                        <label key={platform} className="dropdown-item">
                            <input
                                type="checkbox"
                                checked={selectedPlatforms.includes(platform)}
                                onChange={() => handlePlatformChange(platform)}
                                onClick={(e) => e.stopPropagation()}
                            />
                            <span>{platform.charAt(0).toUpperCase() + platform.slice(1)}</span>
                        </label>
                    ))}
                </DropdownMenu>
                <DropdownMenu title="Brands">
                    {availableBrands.map(brand => (
                        <label key={brand.brand_id} className="dropdown-item">
                            <input
                                type="checkbox"
                                checked={selectedBrands.includes(brand.brand_id)}
                                onChange={() => handleBrandChange(brand.brand_id)}
                                onClick={(e) => e.stopPropagation()}
                            />
                            <span>{brand.brand_name}</span>
                        </label>
                    ))}
                </DropdownMenu>
                <DropdownMenu title="Time">
                    <div className="time-options">
                        {['7d', '30d', '90d', 'ytd', 'all'].map(period => (
                            <div
                                key={period}
                                className={`time-option ${selectedTimePeriod === period ? 'to-dd-selected' : ''}`}
                                onClick={() => setSelectedTimePeriod(period)}
                            >
                                {{
                                    '7d': 'Last 7 Days',
                                    '30d': 'Last 30 Days',
                                    '90d': 'Last 90 Days',
                                    'ytd': 'Year to Date',
                                    'all': 'All Time'
                                }[period]}
                            </div>
                        ))}
                    </div>
                </DropdownMenu>
            </div>
            <div className="ss-container">
                <div className="small-container frosted widget">
                    <p className="widget-title slim-dash">Subscribers Gained</p>
                    <h3>{formatNumber(totalSubscribersGained)}</h3>
                </div>
                <div className="small-container frosted widget">
                    <p className="widget-title slim-dash">Views</p>
                    <h3>{formatNumber(totalViews)}</h3>
                </div>
                <div className="small-container frosted widget">
                    <p className="widget-title slim-dash">Likes</p>
                    <h3>{formatNumber(totalLikes)}</h3>
                </div>
                <div className="small-container frosted widget">
                    <p className="widget-title slim-dash">Comments</p>
                    <h3>{formatNumber(totalComments)}</h3>
                </div>

                <div className="graph-container frosted widget">
                    <div className="widget-title">
                        <p className={`widget-title-item ${graphView === 'views' ? 'selected-graph-option' : ''}`} onClick={() => handleGraphChange('views')}>Views</p>
                        <p className={`widget-title-item ${graphView === 'likes' ? 'selected-graph-option' : ''}`} onClick={() => handleGraphChange('likes')}>Likes</p>
                        <p className={`widget-title-item ${graphView === 'comments' ? 'selected-graph-option' : ''}`} onClick={() => handleGraphChange('comments')}>Comments</p>
                        <p className={`widget-title-item ${graphView === 'followers' ? 'selected-graph-option' : ''}`} onClick={() => handleGraphChange('followers')}>Followers</p>
                    </div>
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart data={chartData} margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="var(--primary-color)" stopOpacity={0.4} />
                                    <stop offset="100%" stopColor="var(--primary-color)" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="date" tickFormatter={formatXAxis} tickLine={false} axisLine={false} tick={{ fontSize: 12 }} />
                            <YAxis tickFormatter={formatYAxis} tickLine={false} axisLine={false} tick={{ fontSize: 12 }} />
                            <Tooltip content={<CustomTooltip />} />
                            <Area type="monotone" dataKey={graphView} stroke="var(--subtitle-color)" fillOpacity={0.4} fill="url(#colorGradient)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
                <div className="normal-container frosted widget">
                    <p className="widget-title bold-dash">Followers / Subscribers</p>
                    <div className="data-skeleton">
                        {Object.entries(aggregatedFollowers).map(([type, count], index) => (
                            <div key={index} className="data-row">
                                <span>
                                    <img src={getLogo(type)} alt={`${type} logo`} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                                </span>
                                <span>{formatNumber(count)}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="medium-container frosted widget">
                    <p className="widget-title bold-dash">Views by Platform</p>
                    <div className="data-skeleton-db-dpc">
                        {platformPieData.length > 0 ? (
                            <>
                                <ResponsiveContainer width="40%" height={200}>
                                    <PieChart>
                                        <Pie
                                            data={platformPieData}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={40}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            paddingAngle={platformPieData.length > 1 ? 5 : 0}
                                            dataKey="value"
                                            stroke="none"
                                            cornerRadius={5}
                                        >
                                            {platformPieData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                                <div style={{ width: '60%', paddingLeft: '20px' }}>
                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        {platformPieData.map((entry, index) => (
                                            <li key={`legend-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                <span style={{
                                                    display: 'inline-block',
                                                    width: '10px',
                                                    height: '10px',
                                                    backgroundColor: COLORS[index % COLORS.length],
                                                    marginRight: '10px',
                                                    borderRadius: '50%',
                                                }}></span>
                                                {entry.name}
                                                <div className="fill" />
                                                {entry.percentage}%
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        ) : (
                            <p className=''>No data available for the selected platforms and brands.</p>
                        )}
                    </div>
                </div>

                <div className="medium-container frosted widget">
                    <p className="widget-title bold-dash">Top Content</p>
                    <div className="data-skeleton">
                        <table className="top-content-table">
                            <thead>
                                <tr className="top-content-header">
                                    <th>Title</th>
                                    <th>Views</th>
                                    <th>Likes</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTopContent.length > 0 ? (
                                    filteredTopContent.map((content, index) => (
                                        <tr key={index} className="top-content-row">
                                            <td className="start-content-col top-content-title">
                                                <div className="logo-title-container">
                                                    <div className="logo-wrapper">
                                                        <img src={getLogo(content.type)} alt={`${content.type} logo`} style={{ width: '25px', height: '25px', marginRight: '10px' }} />
                                                        <img src={content.url_logo} alt={`${content.channelType} logo`} className="channel-logo" />
                                                    </div>
                                                    <span>{content.title}</span>
                                                </div>
                                            </td>
                                            <td className="middle-col">{formatNumber(content.views)}</td>
                                            <td className="middle-col">{formatNumber(content.likes)}</td>
                                            <td className="end-content-col">{formatNumber(content.comments)}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr><td colSpan="4">No top content available</td></tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
