import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';

// Create a custom Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Your API base URL
  withCredentials: true, // Important to send cookies with requests
});

// Axios response interceptor to handle unauthorized errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const isAuthRequiredPage = window.location.pathname.startsWith('/app');
    const isAuthPage = window.location.pathname.startsWith('/auth');

    console.log('error', error.response?.status);
    console.log('isAuthRequiredPage', isAuthRequiredPage);
    console.log('isAuthPage', isAuthPage);

    if (error.response?.status === 401 && !isAuthPage) {
      // Clear existing user data
      QueryClient.setQueryData(['user'], null);
      window.location.href = '/login';
      return Promise.reject(error);
    }

    if (error.response?.status === 403 && isAuthRequiredPage) {
      window.location.href = '/';
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default api;