import React, { useEffect, useState } from 'react';
import './Schedule.css';
import api from '../../../api';
import { capitalize } from '../../../utils';
import { CaretLeft, CaretRight } from 'phosphor-react';

import YoutubeLogo from '../../../assets/logos/youtube.png';
import TiktokLogo from '../../../assets/logos/tiktok.png';

import ScheduleView from './ScheduleView';

const Schedule = ({ team }) => {
    const [sideviewToggle, setSideviewToggle] = useState('platforms');
    const [platforms, setPlatforms] = useState({});
    const [view, setView] = useState('week');
    const [currentDate, setCurrentDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);

    const [cache, setCache] = useState(new Map());
    const [cacheRange, setCacheRange] = useState({ start: null, end: null });
    const [cacheExpiry, setCacheExpiry] = useState(null);

    const [scheduleStats, setScheduleStats] = useState({
        last30d: 0,
        scheduled: 0,
        nextPost: null,
    });

    useEffect(() => {
        // Sort channels by type - for each brand for each socials
        const brands = team.brands;
        const platforms = {};

        brands.forEach(brand => {
            brand.socials.forEach(social => {
                if (!platforms[social.type]) {
                    platforms[social.type] = [];
                }
                platforms[social.type].push(social);
            });
        });

        setPlatforms(platforms);
    }, [team]);

    useEffect(() => {
        updateDateRange();
    }, [view, currentDate]);

    const updateDateRange = () => {
        const start = new Date(currentDate);
        const end = new Date(currentDate);

        if (view === 'week') {
            start.setDate(start.getDate() - start.getDay());
            end.setDate(start.getDate() + 6);
        } else if (view === 'month') {
            start.setDate(1);
            end.setMonth(start.getMonth() + 1);
            end.setDate(0);
        } else if (view === 'list') {
            start.setDate(1);
            end.setMonth(start.getMonth() + 1);
            end.setDate(0);
        }

        setStartDate(start);
        setEndDate(end);
        fetchPosts(start, end);
    };

    const handlePrevious = () => {
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate);
            if (view === 'week') {
                newDate.setDate(newDate.getDate() - 7);
            } else if (view === 'month' || view === 'list') {
                newDate.setMonth(newDate.getMonth() - 1);
            }
            return newDate;
        });
    };

    const handleNext = () => {
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate);
            if (view === 'week') {
                newDate.setDate(newDate.getDate() + 7);
            } else if (view === 'month' || view === 'list') {
                newDate.setMonth(newDate.getMonth() + 1);
            }
            return newDate;
        });
    };

    const fetchPosts = async (start, end) => {
        const now = Date.now();
        start = new Date(start);
        end = new Date(end);

        // Check cache for valid entries covering the requested range
        if (cacheRange.start && cacheRange.end &&
            start >= cacheRange.start && end <= cacheRange.end) {
            const filtered = Array.from(cache.values()).filter(post => {
                const postDate = new Date(post.post_time);
                return postDate >= start && postDate <= end;
            });
            setPosts(filtered);
            return;
        }

        try {
            setLoading(true);
            const res = await api.get('/posts', {
                params: {
                    publicId: team.public_id,
                    startDate: start.toISOString(),
                    endDate: end.toISOString(),
                },
            });

            // Merge new posts into cache
            const newCache = new Map(cache);
            res.data.posts.forEach(post => {
                newCache.set(post.id, post);
            });

            // Update cache range to cover all cached posts
            const postDates = Array.from(newCache.values()).map(p => new Date(p.post_time));
            const newStart = new Date(Math.min(...postDates));
            const newEnd = new Date(Math.max(...postDates));

            setScheduleStats({
                last30d: res.data.stats.recentPosts,
                scheduled: res.data.stats.scheduledPostsCount,
                nextPost: res.data.stats.countdownToNextScheduled,
            });

            setCache(newCache);
            setCacheRange({ start: newStart, end: newEnd });
            setCacheExpiry(now + 1000 * 60 * 60); //1 hour
            setPosts(res.data.posts);
        } catch (error) {
            console.error('Error fetching posts:', error);
        } finally {
            setLoading(false);
        }
    };


    const formatDateRange = (start, end) => {
        const options = { month: 'short', day: 'numeric' };
        const startFormatted = start.toLocaleDateString('en-US', options);
        const endFormatted = end.toLocaleDateString('en-US', { day: 'numeric' });
        const year = start.getFullYear();

        return `${startFormatted} - ${endFormatted}, ${year}`;
    };

    const getPlatformLogo = (platform) => {
        switch (platform) {
            case 'youtube':
                return YoutubeLogo;
            case 'tiktok':
                return TiktokLogo;
            default:
                return '';
        }
    }

    const isCurrentWeek = () => {
        const today = new Date();
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay());
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        return currentDate >= startOfWeek && currentDate <= endOfWeek;
    };

    return (
        <div className='schedule-body'>
            {/* Sidebar */}
            <div className='schedule-side frosted'>
                {/* User Profile Section */}
                <div className='schedule-profile-section'>
                    <div className='schedule-header-section'>
                        <div className='top-toggles'>
                            <button className={`schedule-header-item ${sideviewToggle === 'platforms' ? 'active' : ''}`} onClick={() => setSideviewToggle('platforms')}>
                                <p>Platforms</p>
                            </button>
                            <button className={`schedule-header-item ${sideviewToggle === 'brands' ? 'active' : ''}`} onClick={() => setSideviewToggle('brands')}>
                                <p>Brands</p>
                            </button>
                        </div>
                    </div>

                    <div className='schedule-body-section'>
                        {sideviewToggle === 'platforms' && (
                            <div className='platforms-section'>
                                {Object.keys(platforms).map((platformType, index) => (
                                    <div key={index} className='platform-item'>
                                        <div className='platform-item-header'>
                                            <img src={getPlatformLogo(platformType)} alt={platformType} className='platform-picture img-very-mini' />
                                            {capitalize(platformType)}
                                        </div>
                                        <div className='platform-item-body'>
                                            {platforms[platformType].map((social, idx) => (
                                                <div key={idx} className='social-item'>
                                                    <img src={social.url_logo} alt={social.channel_title} className='team-picture img-very-mini' />
                                                    <p>{social.channel_title}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {sideviewToggle === 'brands' && (
                            <div className='brands-section'>
                                {team.brands.map((brand, index) => (
                                    <div key={index} className='brand-item'>
                                        <div className='platform-item-header'>
                                            <img src={brand.image_url} alt={brand.brand_name} className='team-picture img-very-mini' />
                                            {brand.brand_name}
                                        </div>
                                        <div className='platform-item-body'>
                                            {brand.socials.map((social, idx) => (
                                                <div key={idx} className='social-item'>
                                                    <img src={social.url_logo} alt={social.channel_title} className='team-picture img-very-mini' />
                                                    <p>{social.channel_title}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Main Settings Area */}
            <div className='schedule-main'>
                <div className='dynamic-main-schedule'>
                    <div className='main-header'>
                        <div className='left-top-stats frosted'>
                            <p className='section-header-stats'>
                                Post Schedule
                            </p>
                            <div className='header-stats-body'>
                                <div className='stats-item'>
                                    <h4 className='title-stats-item'>Posted Last 30d</h4>
                                    <p>{scheduleStats.last30d}</p>
                                </div>
                                <div className='stats-divider' />
                                <div className='stats-item'>
                                    <h4 className='title-stats-item'>Scheduled</h4>
                                    <p>{scheduleStats.scheduled}</p>
                                </div>
                                <div className='stats-divider' />
                                <div className='stats-item'>
                                    <h4 className='title-stats-item'>Next Post</h4>
                                    {
                                        scheduleStats.nextPost ? (
                                            <div className='next-post'>
                                                <p>{scheduleStats.nextPost.channel_title}</p>
                                                <p>{new Date(scheduleStats.nextPost.post_time).toLocaleString()}</p>
                                            </div>
                                        ) : (
                                            <p>N/A</p>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                        <div className='right-top-stats frosted'>
                            <p className='section-header-stats'>
                                Status
                            </p>
                            <div className='header-stats-body'>

                            </div>
                        </div>
                    </div>

                    {/* Main Body Schedule */}
                    <div className='main-body-schedule frosted'>
                        <div className='schedule-header-body-schedule'>
                            <p>Calendar</p>
                            <div className='week-navigation'>
                                <CaretLeft size={18} onClick={handlePrevious} className='interactive' />
                                <span>{formatDateRange(startDate, endDate)}</span>
                                <CaretRight size={18} onClick={handleNext} className='interactive' />
                            </div>
                            <div className='fill' />
                            <div onClick={() => !isCurrentWeek() && setCurrentDate(new Date())} className='today-button interactive'>
                                Today
                            </div>
                            <div className='view-select-buttons'>
                                <button className={`view-button ${view === 'week' ? 'active' : ''}`} onClick={() => setView('week')}>Week</button>
                                <button className={`view-button ${view === 'month' ? 'active' : ''}`} onClick={() => setView('month')}>Month</button>
                                <button className={`view-button ${view === 'list' ? 'active' : ''}`} onClick={() => setView('list')}>List</button>
                            </div>
                        </div>

                        {
                            loading ? (
                                <div className='loading'>
                                    <p>Loading...</p>
                                </div>
                            ) : (
                                <ScheduleView posts={posts} view={view} currentDate={currentDate} />
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Schedule;