import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './Auth.css';
import api from '../../api';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(''); // Renamed to avoid conflict with authError
  const navigate = useNavigate();
  const { user, isAuthenticated, isLoading, error: authError, needsMetadata } = useAuth(); // Destructure correctly

  useEffect(() => {
    if (isAuthenticated) {
      if (needsMetadata) {
        navigate('/complete-account');
      } else {
        navigate('/app');
      }
    }
  }, [user,isAuthenticated, needsMetadata, authError, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');

    try {
      const response = await api.post('/login', { email, password });

      if (response.status === 200) {
      const inviteToken = localStorage.getItem('inviteToken');
      if (inviteToken) {
        navigate(`/invite/${inviteToken}`);
        localStorage.removeItem('inviteToken');
      } else {
        window.location.href = '/app';
      }
    }
    } catch (err) {
      setLoginError(err.response?.data?.error || 'Login failed');
    }
  };

  const googleLogin = async () => {
    try {
      const { data } = await api.post('/google-login', {});
      window.location.href = data.url;
    } catch (err) {
      setLoginError(err.response?.data?.error || 'Google login failed');
    }
  };

  if (isLoading) return <p>Loading...</p>;

    return (
        <div className='auth-body'>
            <div className='form-container'>
                <h2 className='login-title'>Welcome Back</h2>
                {loginError && <div className="error-message">{loginError}</div>}
                <form onSubmit={handleLogin}>
                    <div className='inputs'>
                        <div>
                            <label className='label-login'>Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                placeholder='Email'
                            />
                        </div>
                        <div>
                            <label className='label-login'>Password</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                placeholder='Password'
                            />
                        </div>
                    </div>

                    <a href='/forgot-password' className='forgot'>Forgot password?</a>
                    <button type="submit" className='submit-button'>Login</button>

                    {/* already have an account */}
                    <div className='subtitle'>
                        <span>Dont have an account? </span>
                        <a href='/signup'>Sign up</a>
                    </div>

                    <p className='or'>or</p>

                    {/* Sign in with google (with logo) */}
                    <button type="button" className="google-button" onClick={googleLogin}>
                        <img src="https://img.icons8.com/color/48/000000/google-logo.png" alt="Google Logo" height={25} />
                        Sign in with Google
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;