import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, Routes, Route, Link, useParams, Navigate } from 'react-router-dom';
import { ArrowLeft, ArrowLineDownRight, ArrowLineRight, ArrowRight, CaretRight, DotsThree, Info, Plus, Trash, X } from 'phosphor-react';
import api from '../../../../api';

import './Users.css';

import { useAuth } from '../../../../context/AuthContext';
import { useDashboard } from '../../../../hooks/useDashboard';
import { set } from 'date-fns';

const UserManagement = () => {
    const { teamId } = useParams();

    const { data, error, isLoading } = useDashboard(teamId);
    const { teams, selectedTeam } = data;

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [users, setUsers] = useState([]);

    const [isAddUserOpen, setIsAddUserOpen] = useState(false);

    const [newUserData, setNewUserData] = useState([]);
    const [newEmail, setNewEmail] = useState('');
    const [roleInfo, setRoleInfo] = useState(false);

    const [userOptions, setUserOptions] = useState(false);
    const [userDataOptions, setUserDataOptions] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (userOptions) {
            const handleClickOutside = (event) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                    setUserOptions(false);
                    setUserDataOptions(null);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [userOptions]);

    const getUsers = async () => {
        try {
            const response = await api.get(`${teamId}/users`);
            setUsers(response.data.users);
            setFilteredUsers(response.data.users);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    // Format the last login date
    const formatLastLogin = (date) => {
        if (!date) {
            return 'Never';
        }

        return new Date(date).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const filterUsers = (status) => {
        setSelectedFilter(status);
        if (status === 'all') {
            setFilteredUsers(users);
        } else {
            setFilteredUsers(users.filter(user => user.status === status));
        }
    };

    const closeModal = () => {
        setIsAddUserOpen(false);
        setNewUserData([]);
        setNewEmail('');
        setRoleInfo(false);
    };

    const addNewEmail = () => {
        //regex to validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!newEmail || !emailRegex.test(newEmail)) {
            return;
        }

        console.log('Adding new email:', newEmail);
        setNewUserData([...newUserData, { email: newEmail, role: 'member' }])
        setNewEmail('');
        setRoleInfo(false);
    };

    // Submit the new user data to the API
    const handleAddUserSubmit = async () => {
        try {
            const response = await api.post(`${teamId}/invite`, { users: newUserData });
            console.log('Add user response:', response.data);
            closeModal();
            getUsers();
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };

    const removeUser = async (user) => {
        try {
            if (user.user_id) {
                const userId = user.user_id;
                await api.post(`${teamId}/user-remove/${userId}`);
            } else {
                await api.post(`${teamId}/invite/remove`, { email: user.email });
            }
            setUserOptions(false);
            setUserDataOptions(null);
            getUsers();
        } catch (error) {
            console.error('Error removing user:', error);
        }
    };

    const openUserOptions = (user) => {
        console.log('Opening user options:', user);

        setUserOptions(true);
        setUserDataOptions(user);
    }

    return (
        <div className='settings-body-sub'>
            <div className='title-settings'>
                <h2>Users</h2>
                <div className='fill' />
                {/* Open the modal when clicking the New User button */}
                <div className='add-button' onClick={() => setIsAddUserOpen(true)}>
                    <Plus size={15} />
                    <p>New User</p>
                </div>
            </div>
            <div className='dynamic-main-settings frosted'>
                <div className='inner-container'>
                    <div className='main-header-control'>
                        <p onClick={() => filterUsers('all')} className={selectedFilter === 'all' ? 'tableoptionselected' : ''}>
                            All Members
                        </p>
                        <p onClick={() => filterUsers('active')} className={selectedFilter === 'active' ? 'tableoptionselected' : ''}>
                            Active
                        </p>
                        <p onClick={() => filterUsers('invited')} className={selectedFilter === 'invited' ? 'tableoptionselected' : ''}>
                            Invited
                        </p>
                    </div>
                    <div className='main-body-settings'>
                        <table>
                            <thead className='table-header-user'>
                                <tr>
                                    <th><p>Name</p></th>
                                    <th><p>Email</p></th>
                                    <th><p>Role</p></th>
                                    <th><p>Status</p></th>
                                    <th><p>Last Login</p></th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUsers.map((user) => (
                                    <tr key={user.user_id} className='table-row'>
                                        <td>
                                            <p>{user.first_name} {user.last_name}</p>
                                        </td>
                                        <td>
                                            <p>{user.email}</p>
                                        </td>
                                        <td>
                                            <p>{capitalize(user.role)}</p>
                                        </td>
                                        <td>
                                            <p>{capitalize(user.status)}</p>
                                        </td>
                                        <td>
                                            <p>{formatLastLogin(user.last_login)}</p>
                                        </td>
                                        <td className='table-actions' onClick={() => openUserOptions(user)}>
                                            <DotsThree size={20} />
                                            {userOptions && userDataOptions?.email === user.email && (
                                                <div className='dropdown-menu-edit users-dd' ref={dropdownRef}>
                                                    <div className='dropdown-item' onClick={() => console.log('Edit user')}>
                                                        Roles
                                                        <div className='fill' />
                                                        <CaretRight size={14} />
                                                    </div>
                                                    <div className='dropdown-divide' />
                                                    <div className='dropdown-item remove' onClick={() => removeUser(user)}>
                                                        <Trash size={20} />
                                                        Remove User
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            {isAddUserOpen && (
                <div className="modal-overlay" onClick={() => closeModal()}>
                    {roleInfo && (
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-header">
                                <ArrowLeft size={20} onClick={() => setRoleInfo(false)} className='icon interactive' />
                                <h2>Role Info</h2>
                                <div className='fill' />
                            </div>
                            <div className='divider' />
                        </div>
                    )}

                    {!roleInfo && (
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-header">
                                <h2>Add New User</h2>
                                <div className='fill' />
                                <X size={20} onClick={() => closeModal()} className='icon interactive' />
                            </div>
                            <div className='divider' />

                            <h4>Invite by Email</h4>
                            <div className="modal-row">
                                <input
                                    className='newEmailInput'
                                    type="text"
                                    placeholder="Email"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    onKeyDown={(e) => e.key === 'Enter' && addNewEmail()}
                                />
                                <div className={`enter-button-addnew ${newEmail ? 'active' : ''}`}>
                                    <ArrowRight size={14} className='icon interactive' onClick={() => addNewEmail()} />
                                </div>
                            </div>

                            <div className='edit-channel-list'>
                                {newUserData.map((user, index) => (
                                    <div key={index} className='new-user-item'>
                                        <p>{user.email}</p>

                                        <div className='fill' />

                                        <select>
                                            <option value="admin">Admin</option>
                                            <option value="manager">Manager</option>
                                            <option value="post builder">Post Builder</option>
                                            <option value="analyst">Analyst</option>
                                        </select>

                                        <div className='enter-button-addnew'>
                                            <X size={16} onClick={() => setNewUserData(newUserData.filter((_, i) => i !== index))} className='icon interactive' />
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className='fill' />

                            <div className='center-bottom' onClick={() => setRoleInfo(true)}>
                                <Info size={14} className='icon' />
                                Role Info
                            </div>

                            <div className="modal-footer">
                                <button
                                    className="done-button"
                                    onClick={handleAddUserSubmit}
                                    disabled={newUserData.length === 0}
                                >
                                    Send Invites
                                </button>
                            </div>
                        </div>
                    )}

                </div>
            )}
        </div>
    );
};

export default UserManagement;
