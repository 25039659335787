import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Header.css';
import { Bell, MagnifyingGlass } from 'phosphor-react';

const Header = ({ team }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const pathSegments = location.pathname.split('/').filter(Boolean).slice(2); // Remove empty segments and 'app/teamId'

    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    // Construct breadcrumb items with their corresponding paths
    const breadcrumbs = [];
    let currentPath = `/app/${team?.public_id || ''}`; // Base path with teamId

    if (pathSegments.length === 0) {
        // Base case: URL is /app/teamid/, show "Overview"
        breadcrumbs.push({
            label: 'Overview',
            path: currentPath,
        });
    } else {
        // Add subsequent path segments for deeper URLs
        pathSegments.forEach((segment, index) => {
            currentPath += `/${segment}`;
            const label = capitalize(segment);
            breadcrumbs.push({
                label: label,
                path: currentPath,
            });
        });
    }

    const handleBreadcrumbClick = (path) => {
        navigate(path);
    };

    return (
        <header className="header">
            <div className="header-route">
                {breadcrumbs.map((breadcrumb, index) => (
                    <React.Fragment key={index}>
                        <span
                            className="breadcrumb-item"
                            onClick={() => handleBreadcrumbClick(breadcrumb.path)}
                            style={{ cursor: 'pointer', color: index === breadcrumbs.length - 1 ? 'inherit' : 'var(--primary-color)' }}
                        >
                            {breadcrumb.label}
                        </span>
                        {index < breadcrumbs.length - 1 && <span className="breadcrumb-separator"> / </span>}
                    </React.Fragment>
                ))}
            </div>
            <div className='fill'></div>
            <div className='header-actions'>
                <div className="search-container">
                    <MagnifyingGlass size={16} className="search-icon" />
                    <input type="text" placeholder="Search" className="search-input" />
                </div>
                <Bell size={20} className='search-icon-bell' />
            </div>
        </header>
    );
};

export default Header;