import React, { useEffect, useState } from 'react';
import { useTheme } from '../../../../theme-context';

import './Preferences.css';
import { CheckCircle, Circle } from 'phosphor-react';

const Preferences = () => {
    const [isDarkMode, toggleTheme] = useTheme();
    const [is24HourTime, setIs24HourTime] = useState(() => {
        return localStorage.getItem("is24HourTime") === "true";
    });
    const [startWeekOnMonday, setStartWeekOnMonday] = useState(() => {
        return localStorage.getItem("startWeekOnMonday") === "true";
    });
    const [dateFormat, setDateFormat] = useState(() => {
        return localStorage.getItem("dateFormat") || "MM/DD/YYYY";
    });
    const [timeZone, setTimeZone] = useState(() => {
        return localStorage.getItem("timeZone") || Intl.DateTimeFormat().resolvedOptions().timeZone;
    });

    useEffect(() => {
        localStorage.setItem("isDarkMode", isDarkMode);
    }, [isDarkMode]);

    useEffect(() => {
        localStorage.setItem("is24HourTime", is24HourTime);
    }, [is24HourTime]);

    useEffect(() => {
        localStorage.setItem("startWeekOnMonday", startWeekOnMonday);
    }, [startWeekOnMonday]);

    useEffect(() => {
        localStorage.setItem("dateFormat", dateFormat);
    }, [dateFormat]);

    useEffect(() => {
        localStorage.setItem("timeZone", timeZone);
    }, [timeZone]);

    const toggle24HourTime = () => {
        setIs24HourTime(prev => !prev);
    };

    const toggleStartWeekOnMonday = () => {
        setStartWeekOnMonday(prev => !prev);
    };

    const handleDateFormatChange = (event) => {
        setDateFormat(event.target.value);
    };

    const handleTimeZoneChange = (event) => {
        setTimeZone(event.target.value);
    };

    const getTimeZones = () => {
        // Track unique combinations using a Map
        const uniqueZones = new Map();
        
        Intl.supportedValuesOf('timeZone').forEach(zone => {
            // Get UTC offset
            const offsetFormatter = new Intl.DateTimeFormat('en-US', {
                timeZone: zone,
                timeZoneName: 'longOffset'
            });
            const offset = offsetFormatter.formatToParts()
                .find(p => p.type === 'timeZoneName').value
                .replace('GMT', '');
    
            // Get abbreviation
            const abbrevFormatter = new Intl.DateTimeFormat('en-US', {
                timeZone: zone,
                timeZoneName: 'short'
            });
            const abbrev = abbrevFormatter.formatToParts()
                .find(p => p.type === 'timeZoneName').value;
    
            // Create unique key from offset + abbreviation
            const key = `${offset}|${abbrev}`;
            
            // Only keep the first occurrence of each unique combination
            if (!uniqueZones.has(key)) {
                uniqueZones.set(key, {
                    value: zone,
                    label: `UTC${offset} (${abbrev})`,
                    offset: parseOffset(offset)
                });
            }
        });
    
        return Array.from(uniqueZones.values())
            .sort((a, b) => a.offset - b.offset);
    };
    
    // Helper function remains the same
    function parseOffset(offset) {
        const match = offset.match(/^([+-]?)(\d{2}):(\d{2})$/);
        if (!match) return 0;
        const sign = match[1] === '-' ? -1 : 1;
        const hours = parseInt(match[2], 10);
        const minutes = parseInt(match[3], 10);
        return sign * (hours + minutes / 60);
    }
    
    // Initialize options
    const timeZoneOptions = getTimeZones();


    return (
        <div className="settings-body-sub">
            <div className="title-settings">
                <h2>Preferences</h2>
            </div>
            <div className="dynamic-main-settings frosted">
                <div className="inner-container">
                    <h3>Display</h3>
                    <div className='screen-options'>
                        <div className='screen-options-option' onClick={isDarkMode ? toggleTheme : () => { }}>
                            <div className='light-mode-theme-preview'>
                                <div className='mode-theme-preview-inner'>
                                    <div className='preview-inner-left-widget light-widget-preview' />
                                    <div className='main-section-preview'>
                                        <div className='preview-top-bit-preview'>
                                            <div className='preview-top-left light-widget-preview' />
                                            <div className='preview-top-right light-widget-preview' />
                                        </div>
                                        <div className='preview-inner-main light-widget-preview' />
                                    </div>
                                </div>
                            </div>
                            <p>Light Mode</p>
                            <div className='fill' />
                            {
                                !isDarkMode ? (
                                    <CheckCircle size={29} weight='fill' className='icon-theme-select' />
                                ) : <Circle size={29} className='icon-theme-select' />
                            }
                        </div>
                        <div className='screen-options-option' onClick={!isDarkMode ? toggleTheme : () => { }}>
                            <div className='dark-mode-theme-preview'>
                                <div className='mode-theme-preview-inner'>
                                    <div className='preview-inner-left-widget dark-widget-preview' />
                                    <div className='main-section-preview'>
                                        <div className='preview-top-bit-preview'>
                                            <div className='preview-top-left dark-widget-preview' />
                                            <div className='preview-top-right dark-widget-preview' />
                                        </div>
                                        <div className='preview-inner-main dark-widget-preview' />
                                    </div>
                                </div>
                            </div>
                            <p>Dark Mode</p>
                            <div className='fill' />
                            {
                                isDarkMode ? (
                                    <CheckCircle size={29} weight='fill' className='icon-theme-select' />
                                ) : <Circle size={29} className='icon-theme-select' />
                            }
                        </div>
                    </div>

                    <h3>Date and Time</h3>

                    <div className='details-item'>
                        <p className='label'>24 Hour Time</p>
                        <div className='fill' />
                        <label className="switch">
                            <input type="checkbox" checked={is24HourTime} onChange={toggle24HourTime} />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    <div className='details-item'>
                        <p className='label'>Start Week on Monday</p>
                        <div className='fill' />
                        <label className="switch">
                            <input type="checkbox" checked={startWeekOnMonday} onChange={toggleStartWeekOnMonday} />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    <div className='details-item'>
                        <p className='label'>Date Format</p>
                        <div className='fill' />
                        <select value={dateFormat} onChange={handleDateFormatChange} className="dropdown">
                            <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                            <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                            <option value="YYYY-MM-DD">YYYY-MM-DD</option>
                        </select>
                    </div>

                    <div className='details-item'>
                        <p className='label'>Time Zone</p>
                        <div className='fill' />
                        <select value={timeZone} onChange={handleTimeZoneChange} className="dropdown">
                            {timeZoneOptions.map(({ value, label }) => (
                                <option key={value} value={value}>{label}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preferences;
