import React from 'react';
import { formatNumber } from '../../../../utils';

const GeneralAnalytics = ({ data }) => {

    const totalViews = data.reduce((acc, item) => acc + (item.views || 0), 0);
    const totalLikes = data.reduce((acc, item) => acc + (item.likes || 0), 0);
    const totalComments = data.reduce((acc, item) => acc + (item.comments || 0), 0);
    const totalSubscribers = data.reduce((acc, item) => acc + (item.subscribers || item.followers || 0), 0);

    return (
        <div className='analytics-section'>
            <div className='analytics-key-stats'>
                <div className='analytics-stat-item frosted widget'>
                    <p className='widget-title slim-dash'>Views</p>
                    <h3>{formatNumber(totalViews)}</h3>
                </div>
                <div className='analytics-stat-item frosted widget'>
                    <p className='widget-title slim-dash'>Likes</p>
                    <h3>{formatNumber(totalLikes)}</h3>
                </div>
                <div className='analytics-stat-item frosted widget'>
                    <p className='widget-title slim-dash'>Comments</p>
                    <h3>{formatNumber(totalComments)}</h3>
                </div>
                <div className='analytics-stat-item frosted widget'>
                    <p className='widget-title slim-dash'>Subscribers</p>
                    <h3>{formatNumber(totalSubscribers)}</h3>
                </div>
            </div>
        </div>
    );
};

export default GeneralAnalytics;