import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import './Analytics.css';
import GeneralAnalytics from './analytics/general.js';
import DemographicsAnalytics from './analytics/demographics.js';

// Placeholder components for other views
const PostInsights = ({ data }) => <div>Post Insights: {JSON.stringify(data)}</div>;

// Flatten analytics data
const flattenData = (data) => {
    if (typeof data !== 'object' || data === null || Array.isArray(data)) {
        console.error('Invalid data format, expected an object:', data);
        return [];
    }

    if (Object.keys(data).length === 0) {
        console.warn('No platforms found in analytics data:', data);
        return [];
    }

    return Object.keys(data).flatMap((platform) => {
        if (typeof data[platform] !== 'object' || data[platform] === null) {
            console.error(`Invalid data for platform "${platform}":`, data[platform]);
            return [];
        }
        return Object.keys(data[platform]).map((channelId) => {
            const channelData = data[platform][channelId];
            if (typeof channelData !== 'object' || channelData === null) {
                console.error(`Invalid channel data for "${platform}/${channelId}":`, channelData);
                return null;
            }
            return {
                platform,
                channelId,
                ...(channelData.general || {}),
                demographics: channelData.demographics || {},
                postInsights: channelData.postInsights || {},
                brand_id: channelData.brand_id || null,
                type: channelData.type || 'videos',
                timestamp: channelData.timestamp || new Date().toISOString(),
            };
        }).filter(item => item !== null);
    });
};


// Filter function with corrected property names
const filterData = (data, filters) => {
    let filtered = Array.isArray(data) ? [...data] : [];

    if (filters.brand !== 'all') {
        filtered = filtered.filter((item) => item.brand_id === filters.brand);
    }

    if (filters.channel !== 'all') {
        console.log('Filtering by brand:', filters);
        console.log('Data:', filtered);
        filtered = filtered.filter((item) => item.channelId === filters.channel);
    }

    // Type filter
    if (filters.type !== 'all') {
        filtered = filtered.filter((item) => item.type === filters.type);
    }

    return filtered;
};

const Analytics = ({ team }) => {
    const { teamId } = useParams();
    const [page, setPage] = useState('general');
    const [analyticsData, setAnalyticsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Filter state
    const [selectedBrand, setSelectedBrand] = useState('all');
    const [selectedChannel, setSelectedChannel] = useState('all');
    const [selectedType, setSelectedType] = useState('all');
    const [selectedTime, setSelectedTime] = useState('today');

    // Fetch analytics data
    useEffect(() => {
        const fetchAnalytics = async () => {
            try {
                setLoading(true);
                setError(null);
                const { data } = await api.get(`/analytics/${teamId}`);
                setAnalyticsData(data);
                console.log('Fetched analytics:', data);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch analytics');
            } finally {
                setLoading(false);
            }
        };

        if (teamId) fetchAnalytics();
    }, [teamId]);

    // Flatten data only if available
    const flatData = analyticsData ? flattenData(analyticsData) : null;
    const filters = { brand: selectedBrand, channel: selectedChannel, type: selectedType, time: selectedTime };
    const filteredData = flatData ? filterData(flatData, filters) : [];

    if (loading) return <div className="loading-spinner">Loading analytics...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!analyticsData) return <div>No analytics data available</div>;

    return (
        <div className="analytics-body">
            {/* Page Selection */}
            <div className="analytics-page-sel">
                <div
                    className={`analytics-page-sel-item ${page === 'general' ? 'active' : ''}`}
                    onClick={() => setPage('general')}
                >
                    General
                </div>
                <div
                    className={`analytics-page-sel-item ${page === 'demographics' ? 'active' : ''}`}
                    onClick={() => setPage('demographics')}
                >
                    Demographics
                </div>
                <div
                    className={`analytics-page-sel-item ${page === 'posts' ? 'active' : ''}`}
                    onClick={() => setPage('posts')}
                >
                    Post Insights
                </div>
            </div>

            {/* Filters */}
            <div className="analytics-page-filters">
                <div className="analytics-page-filters-item">
                    <label>Brands</label>
                    <select value={selectedBrand} onChange={(e) => setSelectedBrand(e.target.value)}>
                        <option value="all">All</option>
                        {team.brands.map((brand) => (
                            <option key={brand.id} value={brand.brand_id}>
                                {brand.brand_name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="analytics-page-filters-item">
                    <label>Channels</label>
                    <select value={selectedChannel} onChange={(e) => setSelectedChannel(e.target.value)}>
                        <option value="all">All</option>
                        {team.brands.flatMap((brand) =>
                            brand.socials.map((channel) => (
                                <option key={channel.id} value={channel.channel_id}>
                                    {channel.channel_title}
                                </option>
                            ))
                        )}
                    </select>
                </div>
                <div className="analytics-page-filters-item">
                    <label>Type</label>
                    <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                        <option value="all">All</option>
                        <option value="posts">Posts</option>
                        <option value="stories">Stories</option>
                        <option value="shorts">Shorts</option>
                        <option value="videos">Videos</option>
                    </select>
                </div>
            </div>

            {/* Render Pages */}
            {page === 'general' && <GeneralAnalytics data={filteredData} />}
            {page === 'demographics' && <DemographicsAnalytics data={filteredData} />}
            {page === 'posts' && <PostInsights data={filteredData} />}
        </div>
    );
};

export default Analytics;