import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import api from '../../../api';
import './Post.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ArrowLeft, CalendarBlank, Info, NotePencil, Upload, X } from 'phosphor-react';

// Add these imports for date handling
import { format, addMinutes, startOfDay } from 'date-fns';

import {
    renderPlatformSettings,
    PlatformSettingsWrapper,
} from './RenderPlatformSettings';

import { DragDropUpload } from './RenderDragUpload';

const Post = ({ closeModal, selectedTeam }) => {
    const navigate = useNavigate();

    // Existing state declarations...
    const [allChannels, setAllChannels] = useState(selectedTeam.brands.map(brand => brand.socials).flat());
    const [selectedChannels, setSelectedChannels] = useState([]);

    // Add state for date picker
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);

    //media
    const [mediaTitle, setMediaTitle] = useState({});
    const [mediaDescription, setMediaDescription] = useState({});

    //platform settings
    const [platformPage, setPlatformPage] = useState('all');

    const [expandedPlatforms, setExpandedPlatforms] = useState([]);
    const [platformSettings, setPlatformSettings] = useState({
        tiktok: {
            privacy: 'PUBLIC_TO_EVERYONE',
            disableDuet: false,
            disableStitch: false,
            disableComments: false,

            brandedContent: false,
            organicBrand: false,
            is_aigc: false,
        },
        youtube: {
            category: 22,
            privacy: 'public',
            tags: '',
            madeForKids: false,
            is_aigc: false,
        }
    });

    const [platformRequirements, setPlatformRequirements] = useState({
        tiktok: {
            video: true,
            multiVideo: false,
            image: false,
            multiImage: false,

            title: true,
            description: false,
        },
        youtube: {
            video: true,
            multiVideo: false,
            image: false,
            multiImage: false,

            title: true,
            description: true,
        }
    });

    //media handlers
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadError, setUploadError] = useState('');
    const [isDragging, setIsDragging] = useState(false);

    const handleFileUpload = (files) => {
        setUploadError('');
        const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/quicktime'];
        const maxSize = 100 * 1024 * 1024;
        const validFiles = Array.from(files).filter(file => {
            if (!validTypes.includes(file.type)) {
                setUploadError('Invalid file type. Only images (JPEG, PNG, GIF) and videos (MP4, MOV) are allowed.');
                return false;
            }
            if (file.size > maxSize) {
                setUploadError('File size too large. Maximum size is 100MB.');
                return false;
            }
            return true;
        });
        const filesWithPreview = validFiles.map(file => ({
            file,
            preview: URL.createObjectURL(file),
            type: file.type.startsWith('image/') ? 'image' : 'video'
        }));
        setUploadedFiles(prev => [...prev, ...filesWithPreview]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        if (files.length) handleFileUpload(files);
    };

    const removeFile = (index) => {
        setUploadedFiles(prev => prev.filter((_, i) => i !== index));
    };

    const togglePlatformSettings = (platform) => {
        setExpandedPlatforms(prev =>
            prev.includes(platform)
                ? prev.filter(p => p !== platform)
                : [...prev, platform]
        );
    };

    const handlePlatformSettingChange = (platform, field, value) => {
        setPlatformSettings(prev => ({
            ...prev,
            [platform]: {
                ...prev[platform],
                [field]: value
            }
        }));
    };

    const handleCheckboxChange = (channelId, platform) => {
        setSelectedChannels(prevSelectedChannels =>
            prevSelectedChannels.includes(channelId)
                ? prevSelectedChannels.filter(id => id !== channelId)
                : [...prevSelectedChannels, channelId]
        );
    };

    const selectedPlatforms = [...new Set(
        selectedChannels.map(channelId => {
            const channel = allChannels.find(c => c.id === channelId);
            return channel?.type;
        })
    )].filter(Boolean);

    const handleTitleChange = (e) => {
        const value = e.target.value;
        if (platformPage === 'all') {
            setMediaTitle({ all: value });
            setAllChannels(allChannels.map(channel => ({ ...channel, title: value })));
        } else {
            setMediaTitle(prev => ({ ...prev, [platformPage]: value }));
        }
    };

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        if (platformPage === 'all') {
            setMediaDescription({ all: value });
            setAllChannels(allChannels.map(channel => ({ ...channel, description: value })));
        } else {
            setMediaDescription(prev => ({ ...prev, [platformPage]: value }));
        }
    };

    const handleUseAllDescription = () => {
        setMediaDescription(prev => ({ ...prev, [platformPage]: mediaDescription['all'] }));
    };

    const handleUseAllTitle = () => {
        setMediaTitle(prev => ({ ...prev, [platformPage]: mediaTitle['all'] }));
    };

    const handleSubmit = async () => {
        try {
            if (uploadedFiles.length === 0) {
                alert("Please upload media before submitting.");
                return;
            }
            const brandId = allChannels.find(channel => selectedChannels.includes(channel.id)).brand_id;
            const formData = new FormData();
            const platformData = {
                teamId: selectedTeam.oublic_id,
                brandId: brandId,
                mediaTitle,
                mediaDescription,
                selectedChannels: allChannels.filter(c => selectedChannels.includes(c.id)),
                platformSettings,
                scheduled: selectedDate.toISOString() || null,
            };
            uploadedFiles.forEach(file => formData.append('media', file.file));
            formData.append('data', JSON.stringify(platformData));
            const response = await api.post('/upload-content', formData);
            console.log(response.data);
            alert("Upload successful");
            closeModal();
        } catch (error) {
            console.error("Upload failed:", error);
            alert("Upload failed, check console for details");
        }
    };

    const requiresDescription = () => {
        if (platformPage === 'all') {
            return selectedChannels.some(channelId => {
                const channel = allChannels.find(c => c.id === channelId);
                return platformRequirements[channel.type]?.description;
            });
        } else {
            return platformRequirements[platformPage]?.description;
        }
    };

    const generateHourOptions = () => {
        const options = [];
        for (let i = 0; i < 24; i++) {
            const hour = i.toString().padStart(2, '0');
            options.push({
                value: hour,
                label: format(new Date().setHours(i, 0), 'hh a')
            });
        }
        return options;
    };

    const generateMinuteOptions = () => {
        const options = [];
        for (let i = 0; i < 60; i += 15) { // 15-minute increments
            const minute = i.toString().padStart(2, '0');
            options.push({
                value: minute,
                label: minute
            });
        }
        return options;
    };

    const openDatePick = () => {
        setIsDatePickerOpen(true);
    };

    const closeDatePicker = () => {
        setIsDatePickerOpen(false);
    };

    const handleDateChange = (date) => {
        const newDate = new Date(date);
        if (selectedDate) {
            newDate.setHours(selectedDate.getHours());
            newDate.setMinutes(selectedDate.getMinutes());
        } else {
            newDate.setHours(0);
            newDate.setMinutes(0);
        }
        setSelectedDate(newDate);
    };

    const handleHourChange = (e) => {
        const newDate = new Date(selectedDate || new Date()); // Fallback to current date if null
        newDate.setHours(parseInt(e.target.value));
        setSelectedDate(newDate);
    };

    const handleMinuteChange = (e) => {
        const newDate = new Date(selectedDate || new Date()); // Fallback to current date if null
        newDate.setMinutes(parseInt(e.target.value));
        setSelectedDate(newDate);
    };

    const clearSchedule = () => {
        setSelectedDate(null);
        setIsDatePickerOpen(false);
    };

    return (
        <div className='modal-overlay' onClick={closeModal}>
            <div className='modal-content-max' onClick={(e) => e.stopPropagation()}>
                <div className='modal-header-post'>
                    <h3>New Post</h3>
                    <div className='fill' />
                    <X size={20} onClick={closeModal} className='icon interactive' />
                </div>

                <div className='modal-body-post'>
                    <div className='post-column channel-post'>
                        <h4>
                            Channel
                        </h4>
                        <p className='subtitle-posts'>Filter platform</p>
                        <select className='channel-select-post'>
                            <option value=''>All</option>
                            <option value='youtube'>YouTube</option>
                        </select>

                        {
                            allChannels.map(channel => (
                                <label className='channel-post-item' key={channel.id}>
                                    <input
                                        type='checkbox'
                                        className='channel-checkbox-post'
                                        checked={selectedChannels.includes(channel.id)}
                                        onChange={() => handleCheckboxChange(channel.id, channel.type)}
                                    />
                                    <div>
                                        <p className='channel-title-post'>{channel.channel_title}</p>
                                        <p className='channel-type-post'>{channel.type[0].toUpperCase() + channel.type.slice(1)}</p>
                                    </div>
                                </label>
                            ))
                        }
                    </div>
                    <div className={`post-column upload-media ${selectedChannels.length === 0 ? 'media-disabled' : ''}`}>
                        <h4>
                            Your Post
                        </h4>

                        <div className='upload-space'>
                            <DragDropUpload
                                isDragging={isDragging}
                                uploadError={uploadError}
                                uploadedFiles={uploadedFiles}
                                handleDragOver={handleDragOver}
                                handleDragLeave={handleDragLeave}
                                handleDrop={handleDrop}
                                removeFile={removeFile}
                                handleFileUpload={handleFileUpload}
                            />
                        </div>

                        <div className={`media-title-post ` + (requiresDescription() ? '' : 'media-title-post-full')}>
                            {!requiresDescription() ? (
                                <textarea
                                    value={platformPage in mediaTitle ? mediaTitle[platformPage] : (mediaTitle['all'] || '')}
                                    onChange={handleTitleChange}
                                    placeholder='Enter Title'
                                />
                            ) : (
                                <input
                                    type='text'
                                    value={platformPage in mediaTitle ? mediaTitle[platformPage] : (mediaTitle['all'] || '')}
                                    onChange={handleTitleChange}
                                    placeholder='Enter Title'
                                />
                            )}
                            <Info size={18} />

                        </div>
                        {platformPage !== 'all' && (
                            <button type="button" onClick={handleUseAllTitle} className='set-all-post'>
                                Use All Title
                            </button>
                        )}


                        {requiresDescription() && (
                            <>
                                <div className='media-description-post'>
                                    <textarea
                                        value={platformPage in mediaDescription ? mediaDescription[platformPage] : (mediaDescription['all'] || '')}
                                        onChange={handleDescriptionChange}
                                        placeholder='Enter Description'
                                    />
                                </div>
                                {platformPage !== 'all' && (
                                    <button type="button" onClick={handleUseAllDescription} className='set-all-post'>
                                        Use All Description
                                    </button>
                                )}
                            </>
                        )}
                        {platformPage === 'all' && (
                            <div className="variations-section">
                                <h4>Variations</h4>
                                <div className="variants-list">
                                    {['facebook', 'twitter', 'youtube']
                                        .filter(platform => {
                                            const hasCustomTitle =
                                                mediaTitle[platform] && mediaTitle[platform] !== mediaTitle.all;
                                            const hasCustomDesc =
                                                mediaDescription[platform] && mediaDescription[platform] !== mediaDescription.all;
                                            return hasCustomTitle || hasCustomDesc;
                                        })
                                        .map(platform => (
                                            <div key={platform} className="variant-item">
                                                {platform.charAt(0).toUpperCase() + platform.slice(1)}
                                            </div>
                                        ))}
                                </div>
                                {['facebook', 'twitter', 'youtube'].every(platform =>
                                    (!mediaTitle[platform] || mediaTitle[platform] === mediaTitle.all) &&
                                    (!mediaDescription[platform] || mediaDescription[platform] === mediaDescription.all)
                                ) && (
                                        <p className="no-variants">No variations</p>
                                    )}
                            </div>
                        )}
                    </div>
                    <div className={`post-column platform-settings ${selectedChannels.length === 0 ? 'media-disabled' : ''}`} >
                        <h4>
                            Platform Settings
                        </h4>
                        <div className='platform-changes-post'>
                            <div
                                className={`platform-change-page ${platformPage === 'all' ? 'selected-platform' : ''}`}
                                onClick={() => setPlatformPage('all')}
                            >
                                All
                            </div>
                            <div
                                className={`platform-change-page ${platformPage === 'facebook' ? 'selected-platform' : ''} ${!selectedPlatforms.includes('facebook') ? 'disabled' : ''}`}
                                onClick={() =>
                                    selectedPlatforms.includes('facebook') && setPlatformPage('facebook')
                                }
                            >
                                Facebook
                            </div>
                            <div
                                className={`platform-change-page ${platformPage === 'twitter' ? 'selected-platform' : ''} ${!selectedPlatforms.includes('twitter') ? 'disabled' : ''}`}
                                onClick={() =>
                                    selectedPlatforms.includes('twitter') && setPlatformPage('twitter')
                                }
                            >
                                Twitter
                            </div>
                            <div
                                className={`platform-change-page ${platformPage === 'tiktok' ? 'selected-platform' : ''} ${!selectedPlatforms.includes('tiktok') ? 'disabled' : ''}`}
                                onClick={() =>
                                    selectedPlatforms.includes('tiktok') && setPlatformPage('tiktok')
                                }
                            >
                                TikTok
                            </div>
                            <div
                                className={`platform-change-page ${platformPage === 'youtube' ? 'selected-platform' : ''} ${!selectedPlatforms.includes('youtube') ? 'disabled' : ''}`}
                                onClick={() =>
                                    selectedPlatforms.includes('youtube') && setPlatformPage('youtube')
                                }
                            >
                                Youtube
                            </div>
                        </div>
                        <PlatformSettingsWrapper
                            platformPage={platformPage}
                            selectedPlatforms={selectedPlatforms}
                            expandedPlatforms={expandedPlatforms}
                            togglePlatformSettings={togglePlatformSettings}
                            platformSettings={platformSettings}
                            handlePlatformSettingChange={handlePlatformSettingChange}
                        />
                    </div>
                </div>

                {isDatePickerOpen && (
                    <div className='date-picker-overlay' onClick={closeDatePicker}>
                        <div className='date-picker-modal frosted' onClick={(e) => e.stopPropagation()}>
                            <div className='date-picker-header'>
                                <h4>Schedule Post</h4>
                                <X size={20} onClick={closeDatePicker} className='icon interactive' />
                            </div>
                            <div className='date-picker-content'>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    inline
                                    minDate={new Date()}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                <div className='time-selectors'>
                                    <div className='time-input-group'>
                                        <select
                                            value={format(selectedDate, 'HH')}
                                            onChange={handleHourChange}
                                            className='time-select'
                                        >
                                            {generateHourOptions().map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                        <span className='time-separator'>:</span>
                                        <select
                                            value={format(selectedDate, 'mm')}
                                            onChange={handleMinuteChange}
                                            className='time-select'
                                        >
                                            {generateMinuteOptions().map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='date-picker-footer'>
                                <button onClick={clearSchedule}>Clear</button>
                                <button onClick={closeDatePicker}>Confirm</button>
                            </div>
                        </div>
                    </div>
                )}

                <div className='modal-footer-post'>
                    <div className='media-status-post'>
                        {/* post status eg aspect ratio, file name, file size, length */}
                    </div>
                    <div className='fill' />
                    <div className='icon-post-button'>
                        <NotePencil size={18} />
                    </div>
                    <div className='icon-post-button' onClick={openDatePick}>
                        <CalendarBlank size={18} />
                    </div>
                    {selectedDate && (
                        <div className='date-display'>
                            {format(selectedDate, 'MMM d, yyyy')}
                            <br />
                            {format(selectedDate, 'h:mm a')}
                        </div>
                    )
                    }

                    <button className='next-post' onClick={handleSubmit}>
                        {
                            selectedDate ? 'Schedule' : 'Post'
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Post;