import React, { useState } from 'react';
import { X, Upload, CaretLeft, CaretRight } from 'phosphor-react';

export const DragDropUpload = ({
    isDragging,
    uploadError,
    uploadedFiles,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    removeFile,
    handleFileUpload
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prev) => (prev + 1) % uploadedFiles.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prev) => (prev - 1 + uploadedFiles.length) % uploadedFiles.length);
    };

    return (
        <div
            className={`${isDragging ? 'drag-over' : ''} ${uploadError ? 'has-error' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <input
                type="file"
                id="file-upload"
                multiple
                accept="image/*, video/*"
                onChange={(e) => handleFileUpload(e.target.files)}
                className="visually-hidden"
            />

            {uploadedFiles.length > 0 ? (
                <div className="preview-container">
                    {/* Blurred background */}
                    <div className="blurred-background">
                        {uploadedFiles[currentIndex].type === 'image' ? (
                            <img src={uploadedFiles[currentIndex].preview} alt="background" />
                        ) : (
                            <video>
                                <source src={uploadedFiles[currentIndex].preview} type={uploadedFiles[currentIndex].file.type} />
                            </video>
                        )}
                    </div>

                    {/* Foreground controls */}
                    <div className='foreground-uploading'>
                        <button
                            type="button"
                            className="remove-file"
                            onClick={() => {
                                removeFile(currentIndex);
                                setCurrentIndex(prev => prev > 0 ? prev - 1 : 0);
                            }}
                        >
                            <X size={20} />
                        </button>

                        {uploadedFiles.length > 1 && (
                            <>
                                <button
                                    className="nav-arrow left"
                                    onClick={handlePrev}
                                >
                                    <CaretLeft size={24} />
                                </button>
                                <button
                                    className="nav-arrow right"
                                    onClick={handleNext}
                                >
                                    <CaretRight size={24} />
                                </button>
                            </>
                        )}
                    </div>

                    {/* Main preview */}
                    <div className="main-preview">
                        {uploadedFiles.map((file, index) => (
                            <div
                                key={index}
                                className={`file-preview ${index === currentIndex ? 'active' : ''}`}
                            >
                                {file.type === 'image' ? (
                                    <img src={file.preview} alt={file.file.name} />
                                ) : (
                                    <div className="video-preview">
                                        <video>
                                            <source src={file.preview} type={file.file.type} />
                                        </video>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    <button 
                        className="add-more-button"
                        onClick={() => document.getElementById('file-upload').click()}
                    >
                        <Upload size={16} />
                    </button>
                </div>
            ) : (
                <div className='upload-container-inner'>
                    <label htmlFor="file-upload" className="upload-label">
                        <Upload size={32} />
                        <div className="upload-instructions">
                            {uploadError ? (
                                <span className="error-message">{uploadError}</span>
                            ) : (
                                <>
                                    <span>Drag & drop files here or click to upload</span>
                                    <small>Supported formats are based off the targetted platforms</small>
                                </>
                            )}
                        </div>
                    </label>
                </div>
            )}
        </div>
    );
};