import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../api';

const Success = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyAndStore = async () => {
            const query = new URLSearchParams(location.search);

            const sessionId = query.get('session_id');

            console.log('Session ID:', sessionId);
            //log queries
            console.log('Queries:', query);

            try {
                const response = await api.post('/success', {
                    sessionId,
                    withCredentials: true,
                });
                
                //if the payment was successful
                if (response.status === 200) {
                    navigate('/app');
                }


            } catch (err) {
                console.error('Verification failed', err);
                navigate('/');
            }
        };

        verifyAndStore();
    }, [location.search, navigate]);

    return <h1>Processing your purchase...</h1>;
};

export default Success;